import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withTranslation } from 'react-i18next'
import { Container } from "reactstrap"

import FormHeader from "../FormHeader"
import Under18Form from "./Under18Form"
import Interested from "./Interested"

import { setForm } from "../redux/action"
import history from "../../../util/history"

const Submit = props => {
    useEffect(() => {
        if (!props.form1 || Object.keys(props.form1).length === 1) {
            history.push("/form1")
        }
    }, [])

    return <>
        <FormHeader />
        <Container className="pb-3">
            {props.form1.under18 && !props.form1.goToSubmit ? <Under18Form t={props.t} /> : <Interested t={props.t} />}
        </Container>
    </>
}

const withMultiLanguage = withTranslation("common")(Submit)

const mapStateToProps = state => ({
    form1: state.importForm.form1
})

const mapDispatchToProps = {
    setForm
}

export default connect(mapStateToProps, mapDispatchToProps)(withMultiLanguage)

