export default (
  state = {
    enrollmentUser: [],
  },
  action,
) => {
  switch (action.type) {
    case "SET_STUDENT_ENROLLMENT_USER_LIST":
      return {
        ...state,
        enrollmentUser: action.payload,
        currentPage: action.currentPage,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
      };
    default:
      return state;
  }
};
