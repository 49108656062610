import React from "react"

import { Navbar, Container } from "reactstrap"

import LangSelected from "../../components/Selected/LangSelected"

export default () => <Container>
    <Navbar color="transparent" className="mb-3 px-0">
        <img src="/assets/img/base23.png" alt="logo" style={{ width: "270px", height: "90px" }} />
        <LangSelected />
    </Navbar>
</Container>