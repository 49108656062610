const initState = {
    semester: "",
    year: ""
}

export default function (state = initState, action){
    switch (action.type) {
        case 'SET_SEMESTER_FILTER':
            const semester = action.payload;
            return {
                ...state,
                semester: semester,
            };
        case 'SET_YEAR_FILTER':
            const year = action.payload;
            return {
                ...state,
                year: year,
            };
        default:
            return state;
    }
}
