
export default (
  state = {
    holiday: [],
    holidayInformation: {},
    pageSize: localStorage.getItem("holidayPageSize") ? Number(localStorage.getItem("holidayPageSize")) : 30
  },
  action,
) => {
  switch (action.type) {
    case "GET_HOLIDAY_LIST":
      return {
        ...state,
        holiday: action.payload,
        currentPage: action.currentPage,
        searchString: action.searchString,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
      };
    case "GET_HOLIDAY_INFORMATION":
      return {
        ...state,
        holidayInformation: action.payload,
      };

    case "CHANGE_PAGE_SIZE_HOLIDAY":
      return {
        ...state,
        pageSize: action.payload
      }
    default:
      return state;
  }
};
