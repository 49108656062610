import { combineReducers } from "redux";

import loading from "./components/Spinner/reducer";
import alert from "./components/Alert/reducer";
import modal from "./components/Modal/reducer";
import inputModal from "./components/ModalInput/reducer";
import report from "./containers/Report/redux/reducer";
import studioName from "./containers/StudioName/redux/reducer";
import holiday from "./containers/Holiday/redux/reducer";
import family from "./containers/Family/redux/reducer";
import parent from "./containers/Report/redux/parentReducer";
import teacher from "./containers/Report/redux/teacherReducer";
import student from "./containers/Report/redux/studentReducer";
import assistance from "./containers/Report/redux/assistanceReducer";
import course from "./containers/Course/redux/reducer";
import lession from "./containers/Course/redux/lessionReducer";
import program from "./containers/Program/redux/reducer";
import studentEnrollment from "./containers/StudentEnrollment/redux/reducer";
import enrollmentUser from "./containers/Report/redux/enrollmentUserReducer";
import common from "./containers/Common/reducer";
import courseType from "./containers/Type/redux/reducer";
import skill from "./containers/Skill/redux/reducer";
import schema from "./containers/Schema/redux/reducer";
import extraSalaryCategory from "./containers/ExtraSalaryCategory/redux/reducer";
import payment from "./containers/Payment/redux/reducer";
import salary from "./containers/TeacherSalary/redux/reducer";
import waitingCourse from "./containers/WaitingCourse/redux/reducer";

import templates from "./containers/Email/redux/templateReducer";
import categories from "./containers/Email/redux/categoryReducer";

import importForm from "./containers/FormImportStudent/redux/reducer";

import subject from "./containers/Subject/redux/reducer";
import academicClass from "./containers/AcademicClass/redux/reducer";

import teachingHour from "./containers/TeachingHours/redux/reducer";
import semester from "./containers/SetupOther/redux/reducer";

import studentNoSourse from "./containers/User/redux/reducer";
import semesterReducer from "./components/Selected/redux/reducer";

import dropinStudentReducer from "./containers/User/DropInStudents/redux/reducer";
import teacherOrder from './containers/Order/redux/reducer'

const root = {
  loading,
  alert,
  report,
  modal,
  holiday,
  studioName,
  family,
  parent,
  student,
  assistance,
  course,
  teacher,
  lession,
  program,
  studentEnrollment,
  inputModal,
  enrollmentUser,
  common,
  courseType,
  skill,
  schema,
  templates,
  categories,
  extraSalaryCategory,
  payment,
  salary,
  waitingCourse,
  importForm,
  subject,
  academicClass,
  semester,
  teachingHour,
  studentNoSourse,
  semesterReducer,
  dropinStudentReducer,
  teacherOrder
};

export default combineReducers(root);
