
export default (
  state = {
    program: [],
    programInformation: {},
    totalProgram: [],
    totalProgramWithStudent: [],
    programsOfStudent: [],
    pageSize: localStorage.getItem("programPageSize") ? Number(localStorage.getItem("programPageSize")) : 30,
    searchString: ''
  },
  action,
) => {
  switch (action.type) {
    case "GET_PROGRAM_LIST":
      return {
        ...state,
        program: action.payload,
        currentPage: action.currentPage,
        searchString: action.searchString,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
      };
    case "GET_PROGRAM_INFORMATION":
      return {
        ...state,
        programInformation: action.payload,
      };
    case "GET_COURSE_OF_PROGRAM": {
      return {
        ...state,
        program: [...state.program].map(m => {
          if (m.id === action.id) {
            m.courses = action.payload;
          }
          return m;
        }),
      };
    }
    case "GET_PROGRAM_NO_PAGING_LIST":
      return {
        ...state,
        totalProgram: action.payload,
      };
    case "GET_PROGRAM_NO_PAGING_LIST_WITH_STUDENT":
      return {
        ...state,
        totalProgramWithStudent: action.payload,
      };

    case "CHANGE_PAGE_SIZE_PROGRAM":
      return {
        ...state,
        pageSize: action.payload
      }

    case "SET_PROGRAM_SEARCH_STRING":
      return {
        ...state,
        searchString: action.payload
      }

    default:
      return state;
  }
};
