import { api } from "../../util/api";
import setLoading from "../../components/Spinner/action";
import setModal from "../../components/Modal/action";

export const changePageSize = (size, cb) => async dispatch => {
    dispatch({
        type: "SET_PAGE_SIZE",
        payload: size,
    });
    if (cb) cb(size);
};

export const saveSetupTab = (hf) => async dispatch => {
    dispatch({
        type: "SAVE_SETUP_TAB",
        payload: hf,
    });
}


export const setTableZoneWidth = (w) => async dispatch => {
    dispatch({
        type: "SET_TABLE_ZONE_WIDTH",
        payload: w,
    });
}

export const setUserInfo = (userInfo) => ({
    type: "SET_USER_INFO",
    userInfo,
})

export const getUserInfo = () => async dispatch => {
    const res = await api.get('account/info');
    if (res.status === 200) {
        dispatch(setUserInfo(res.data))
    }
    return res.data;
}

export const onChangeLanguage = lang => async dispatch => {
    dispatch(setLoading(true));
    try {
        dispatch({
            type: "LANGUAGE_CHANGE",
            payload: lang,
        });
    } catch (error) { }
    dispatch(setLoading(false));
};