
export default (
    state = {
        template: [],
        totalTemplate: [],
        pageSize: localStorage.getItem("templatePageSize") ? Number(localStorage.getItem("templatePageSize")) : 30,
        searchString: ''
    },
    action
) => {
    // console.log(action)
    switch (action.type) {
        case "GET_TEMPLATE_LIST":
            return {
                ...state,
                template: action.payload,
                currentPage: action.currentPage,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount
            }

        case "GET_TOTAL_TEMPLATE":
            return {
                ...state,
                totalTemplate: action.payload
            }

        case "CHANGE_PAGE_SIZE_TEMPLATE":
            return {
                ...state,
                pageSize: action.payload
            }

        case "SET_TEMPLATE_SEARCH_STRING":
            return {
                ...state,
                searchString: action.payload
            }

        default:
            return state
    }
}