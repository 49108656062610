export default (state = {
    teachingHourReports: [],
    currentPage: 1,
    pageSize: localStorage.getItem("teachingHourPageSize") ? Number(localStorage.getItem("teachingHourPageSize")) : 30,
    pageCount: 0,
    totalItems: 0
}, action) => {
    switch (action.type) {
        case "SET_TEACHING_HOUR_LIST":
            return {
                ...state,
                teachingHourReports: action.payload,
                currentPage: action.currentPage,
                pageCount: action.pageCount,
                totalItems: action.totalItems
            }
        case "CHANGE_PAGE_SIZE_TEACHING_HOUR":
            return {
                ...state,
                pageSize: action.payload
            }
        default:
            return state
    }
}