
export default (
  state = {
    courses: [],
    msReports: [],
    tcReports: [],
    stReports: [],
    urReports: [],
    subReports: [],
    studentReports: [],
    users: [],
    oldUsers: [],
    teacherSalaryCurrentPage: 1,
    teacherSalaryPageSize: 10,
    teacherSalaryPageCount: 1,
    teacherSalaryTotalItems: 0,
    userInformation: {},
    classMissingPageSize: localStorage.getItem("classMissingPageSize") ? Number(localStorage.getItem("classMissingPageSize")) : 30,
    teacherAbsencePageSize: localStorage.getItem("teacherAbsencePageSize") ? Number(localStorage.getItem("teacherAbsencePageSize")) : 30,
    classSubmittedPageSize: 30,
    studentPageSize: localStorage.getItem("studentPageSize") ? Number(localStorage.getItem("studentPageSize")) : 30,
    userPageSize: localStorage.getItem("userPageSize") ? Number(localStorage.getItem("userPageSize")) : 30,
    importedPageSize: localStorage.getItem("importedPageSize") ? Number(localStorage.getItem("importedPageSize")) : 30,
    adminStaffPageSize: localStorage.getItem("adminStaffPageSize") ? Number(localStorage.getItem("adminStaffPageSize")) : 30,
    oldUserPageSize: 30,
    totalStudentReports: 0,
    searchStringAdmin: "",
    searchString: "",
    importedCurrentPage: 1
  },
  action,
) => {
  switch (action.type) {
    case "SET_COURSE_LIST":
      return {
        ...state,
        courses: action.payload,
      };
    case "SET_REPORT_SEARCH_STRING":
      return {
        ...state,
        searchString: action.payload.searchString,
        currentPage: 1,
      }
    case "SET_REPORT_SEARCH_STRING_ADMIN":
      return {
        ...state,
        searchStringAdmin: action.payload.searchStringAdmin,
        currentPage: 1,
      }
    case "SET_REPORT_MISSING":
      return {
        ...state,
        msReports: action.payload,
        msCurrentPage: action.msCurrentPage,
        msTotalItems: action.msTotalItems,
        msPageCount: action.msPageCount,
      };
    case "SET_REPORT_TEACHER":
      return {
        ...state,
        tcReports: action.payload,
        tcCurrentPage: action.tcCurrentPage,
        tcTotalItems: action.tcTotalItems,
        tcPageCount: action.tcPageCount,
      };
    case "SET_REPORT_STUDENT":
      return {
        ...state,
        stReports: action.payload
      };
    case "SET_REPORT_SUBMIT":
      return {
        ...state,
        subReports: action.payload,
        subCurrentPage: action.subCurrentPage,
        subTotalItems: action.subTotalItems,
        subPageCount: action.subPageCount,
      };
    case "SET_STUDENT_SUBMIT":
      return {
        ...state,
        studentReports: action.payload,
        studentCurrentPage: action.studentCurrentPage,
        studentTotalItems: action.studentTotalItems,
        studentPageCount: action.studentPageCount,
      };
    case "SET_REPORT_USER":
      return {
        ...state,
        urReports: action.payload,
        urCurrentPage: action.urCurrentPage,
        urTotalItems: action.urTotalItems,
        urPageCount: action.urPageCount,
      };
    case "SET_USER_LIST":
      return {
        ...state,
        users: action.payload,
        currentPage: action.currentPage || state.currentPage,
        // searchString: action.searchString,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        roleFilters: action.roleFilters,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
        importedPageSize: action.importedPageSize || state.importedPageSize,
        adminStaffPageSize: action.adminStaffPageSize || state.adminStaffPageSize,
        importedCurrentPage: action.importedCurrentPage || state.importedCurrentPage,
        adminStaffCurrentPage: action.adminStaffCurrentPage + 1 || state.adminStaffCurrentPage
      };
    case "SET_USER_INFORMATION":
      return {
        ...state,
        userInformation: action.payload,
      };

    case "CHANGE_PAGE_SIZE_CLASS_MISSING":
      return {
        ...state,
        classMissingPageSize: action.payload
      }

    case "CHANGE_PAGE_SIZE_TEACHER_ABSENCE":
      return {
        ...state,
        teacherAbsencePageSize: action.payload
      }

    case "CHANGE_PAGE_SIZE_CLASS_SUBMITTED":
      return {
        ...state,
        classSubmittedPageSize: action.payload
      }
    case "CHANGE_PAGE_SIZE_STUDENT_BY_LIST":
      return {
        ...state,
        studentPageSize: action.payload
      }

    case "CHANGE_PAGE_SIZE_USER":
      return {
        ...state,
        userPageSize: action.payload
      }

    case "CHANGE_PAGE_SIZE_IMPORTED":
      return {
        ...state,
        importedPageSize: action.payload
      }

    case "CHANGE_PAGE_SIZE_ADMIN_STAFF":
      return {
        ...state,
        adminStaffPageSize: action.payload
      }

    case "CHANGE_PAGE_SIZE_OLD_USERS":
      return {
        ...state,
        oldUserPageSize: action.payload,
      }

    case "WRITE_USER_STATE":
      return {
        ...state,
        searchString: action.searchString,
      }

    case "REMOVE_USER_STATE":
      return {
        ...state,
        users: [],
        searchString: "",
      }

    case "SET_TEACHER_SALARY":
      return {
        ...state,
        teacherSalaryPageCount: action.payload.pageCount,
        teacherSalaryPageSize: action.payload.pageSize,
        teacherSalaryCurrentPage: action.payload.currentPage + 1,
        teacherSalaryTotalItems: action.payload.totalItems
      }

    case "SET_PAYMENT":
      return {
        ...state,
        paymentPageCount: action.payload.pageCount,
        paymentPageSize: action.payload.pageSize,
        paymentCurrentPage: action.payload.currentPage + 1,
        paymentTotalItems: action.payload.totalItems
      }

    case "GET_OLD_USERS":
      return {
        ...state,
        oldUsers: action.payload,
        ouCurrentPage: action.ouCurrentPage,
        ouTotalItems: action.ouTotalItems,
        ouPageCount: action.ouPageCount
      }

    case "SET_TOTAL_STUDENT_REPORT":
      return {
        ...state,
        totalStudentReports: action.payload
      }

    default:
      return state;
  }
};
