
export default (
  state = {
    course: [],
    totalCourse: [],
    totalCourseWithStudent: [],
    courseType: [],
    coursesOfStudent: [],
    courseInformation: {},
    createStatus: 0,
    editStatus: 0,
    cloneCourseId: null,
    isCloneStudent: false,
    searchString: '',
    currentPage: 1,
    allInvoiceSent: 0,
    waitingCourse: [],
    pageSize: localStorage.getItem("coursePageSize") ? Number(localStorage.getItem("coursePageSize")) : 30,
    currentPage: 1,
    optionsSave: []
  },
  action,
) => {
  switch (action.type) {
    case "GET_COURSE_LIST":
      return {
        ...state,
        course: action.payload,
        currentPage: action.currentPage,
        searchString: action.searchString,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
        allInvoiceSent: action.allInvoiceSent
      };
    case "GET_COURSE_INFORMATION":
      return {
        ...state,
        courseInformation: action.payload,
      };
    case "GET_COURSES_OF_STUDENT":
      return {
        ...state,
        coursesOfStudent: action.payload,
      };
    case "GET_COURSE_NO_PAGING_LIST":
      return {
        ...state,
        totalCourse: action.payload,
      };

    case "GET_COURSE_NO_PAGING_LIST_WITH_STUDENT":
      return {
        ...state,
        totalCourseWithStudent: action.payload,
      };

    case "CHANGE_PAGE_SIZE_COURSE":
      return {
        ...state,
        pageSize: action.payload
      }

    case "TOGGLE_CLONE_COURSE":
      return {
        ...state,
        cloneCourseId: action.payload
      }

    case 'SET_IS_CLONE_STUDENT':
      return {
        ...state,
        isCloneStudent: action.payload
      }
    case "SET_COURSE_SEARCH_STRING":
      return {
        ...state,
        searchString: action.payload
      }
    case "RESET_COURSE_SEARCH":
      return {
        ...state,
        currentPage: 1
      }
    case "SAVE_ASSISTANCE_OPTIONS":
      return {
        ...state,
        optionsSave: action.payload
      }
    default:
      return state;
  }
};
