export default (
    state = {
      totalUser: [],
    },
    action,
  ) => {
    switch (action.type) {
      case "SET_ASSISTANCE_LIST":
        return {
          ...state,
          totalUser: action.payload,
        };
      default:
        return state;
    }
  };
  