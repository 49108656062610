import uniqueId from 'lodash/uniqueId';
import encodeParams from './encodeParams';

let clientId = process.env.REACT_APP_GRAPH_CLIENT_ID;
let redirectUri = process.env.REACT_APP_GRAPH_REDIRECT_URI;
if (process.env.REACT_APP_TEST_SITE) {
  clientId = process.env.REACT_APP_TEST_GRAPH_CLIENT_ID;
  redirectUri = process.env.REACT_APP_TEST_GRAPH_REDIRECT_URI;
}

const popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
  const height = window.innerHeight

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(url, title,
    `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  )
  if (!newWindow) {
    throw new Error("An error occurred! Make sure the browser does not block pop-ups")
  }
  if (newWindow.focus) newWindow.focus();
  return newWindow;
}

export const openMsGraphLoginWindow = (state) => {
  const uri = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
  const params = {
    client_id: clientId,
    response_type: 'code',
    redirect_uri: redirectUri,
    response_mode: 'query',
    scope: 'https://graph.microsoft.com/mail.readwrite https://graph.microsoft.com/Mail.Send',
    state,
  }
  return popupCenter({ url: `${uri}?${encodeParams(params)}`, title: 'Base23', w: 600, h: 480 });
}

export const tryGetAuthCode = () => new Promise((resolve, reject) => {
  const currentState = uniqueId(new Date().toISOString())
  const opener = openMsGraphLoginWindow(currentState)
  const timer = setInterval(() => {
    if (opener.closed) {
      clearInterval(timer);
      const state = localStorage.getItem('graph@state')
      const code = localStorage.getItem('graph@code')
      if (state === currentState) {
        resolve(code)
      } else {
        reject(new Error("Login session expired"));
      }
    }
  }, 1000);
})