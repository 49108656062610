export default (
  state = {
    totalUser: [],
  },
  action,
) => {
  switch (action.type) {
    case "SET_TEACHER_LIST":
      return {
        ...state,
        totalUser: action.payload,
      };
    default:
      return state;
  }
};
