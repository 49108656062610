import axios from "axios";
import { getAccessToken } from "./Common";
import { tryGetAuthCode } from "./GraphServices";
import encodeParams from "./encodeParams";
import store from '../store'
import { semester } from "./Constant";

let BASE_ENDPOINT = process.env.REACT_APP_BASE_ENDPOINT;
if (process.env.REACT_APP_TEST_SITE) {
  BASE_ENDPOINT = process.env.REACT_APP_TEST_BASE_ENDPOINT;
}
export const LOGIN_ENDPOINT = `/account/loginadmin`;
export const AUTHEN_ENDPOINT = `/account/authentication`
export const REPORT_ENDPOINT = `/attendance`;
export const HOLIDAY_ENDPOINT = `/holiday`;
export const FILE = "/file";
export const STUDENT_ENROLLMENT_ENDPOINT = `/studentenrollment`;
export const PROGRAM_ENDPOINT = `/program`;
export const PAYMENT_ENDPOINT = `/payment`;
export const FAMILY_ENDPOINT = `/family`;
export const DASHBOARD_ENDPOINT = `/dashboard`;
export const EXTRA_SALARY_CATEGORY_ENDPOINT = `/salary/category`;
export const STUDIO_ENDPOINT = `/studioName`;
export const COURSE_ENDPOINT = `/course`;
export const COURSE_TYPE = `/coursetype`;
export const SKILL = `/teacherskill`;
export const LESSON_ENDPOINT = `/lession`;
export const TEACHER_ENDPOINT = `/user`;
export const TEACHER_CONTROLLER_ENDPOINT = `/teacher`;
export const USER_INFORMATION = id => `/user/${id}`;
export const RESET_PASSWORD_TEACHER_ENDPOINT = `/teacher/adminResetPassword`;
export const REMOVE_ATTENDANCE_ENDPOINT = `/attendance/remove`;
export const CREATE_USER = "/user";
export const TEMPLATE_ENDPOINT = `/mailtemplate`
export const CATEGORY_ENDPOINT = `/mailtemplatecategory`
export const CREATEMAIL_ENDPOINT = `/mailtemplate/createmail`
export const SENDMAIL_ENDPOINT = `/mailtemplate/sendmail`
export const TEACHER_SALARY_ENDPOINT = `/teacher/teacherSalary`
export const TOTAL_TEACHER_SALARY_ENDPOINT = `/teacher/totalSalary`
export const OLD_USERS = `/user/oldusers`
export const REMOVE_OLD_USERS = `/user/removeusers`
export const PAYMENT = `/payments`
export const ORDER = "/teacherorder";

export const api = axios.create({
  baseURL: BASE_ENDPOINT,
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: params => {
    return encodeParams(params)
  }
});

export const apiUpload = axios.create({
  baseURL: BASE_ENDPOINT,
  timeout: 15000,
  headers: {
    "Content-Type": "multipart/form-data",
  }
});

[api, apiUpload].forEach(i => i.interceptors.request.use(
  config => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config
  },
  error => Promise.reject(error)
))

export const fetchUserByRole = ({ role, searchString, teacherSkillIds, moreData, categoryType, semester, year }) => api.get('user/userByRole', { params: { role, searchString, teacherSkillIds, moreData, categoryType, semester, year } })
export const fetchGraphAuthCode = (code) => api.get('/msgraph/authorize', { params: { code } })
// export const tryCallGraphApi = async (handler) => {
//   const res = await handler()
//   if (res && res.data && res.data === 401) {
//     const code = await tryGetAuthCode();
//     const authResult = await fetchGraphAuthCode(code);
//     if (authResult) {
//       const lastRes = await handler();
//       if (lastRes.data !== 401) {
//         return lastRes
//       }
//     }
//     throw new Error("An error occured! Make sure you login correct account")
//   }
//   return res;
// }
export const fetchDays = (year, month) => api.get('lession/GetDayHasSellableClassInMonth', { params: { year, month }})

export const fetchAllSellableCourse = (date) => api.get('lession/getAllSellableClassInDay', { params: { date }})

export const fetchAllStudioBooking = (date) => api.get('studioBooking', { params: { date }})

export const fetchAllStudioInWeek = (date) => api.get('studioBooking/getAllStudioInWeek', { params: { date }})

export const fetchCreateStudioBooking = (data) => api.post('studioBooking', data)

export const fetchUpdateStudioBooking = (data) => api.put('studioBooking', data)

export const fetchDeleteStudioBooking = (id) => api.delete(`studioBooking/${id}`)

export const fetchAllStudio = () => api.get(`${STUDIO_ENDPOINT}/?currentPage=-1`)

export const fetchUserByUserIds = ({ userIds, semester, year }) => api.get('user/getByUserIds', { params: { userIds, semester, year } })

export const fetchAllCourse = ({ year, semester, searchString, isWaiting, isSmu }) => api.get('course/?currentPage=-1', { params: { year, semester, searchString, isWaiting , isSmu} })

export const fetchCourseWithStudent = ({ searchString }) => api.get('course/getallcoursewithstudent', { params: { searchString } })

export const fetchSemesters = () => api.get('semester')

export const updateSemester = (data) => api.put('semester', data)

export const fetchHolidaysByYear = (year) => api.get(`${HOLIDAY_ENDPOINT}/getHolidaysByYear`, { params: { year } });

export const fetchStudentsInSMU = () => api.get(`${COURSE_ENDPOINT}/getStudentsInSMU`)

export const fetchSMUPrograms = () => api.get(`${COURSE_ENDPOINT}/getSMUPrograms`)

export const fetchSMUSubjects = (year, semester, studentId, programId) => api.get(`${COURSE_ENDPOINT}/GetSMUSubjects`, { params: { year, semester, studentId, programId } })

export const fetchSMUAbsenceInfoInSemester = (year, semester, studentId, programId) => api.get(`${COURSE_ENDPOINT}/getSMUAbsenceInfoInSemester`, { params: { year, semester, studentId, programId } })

export const fetchSMUAbsenceInfoInDateRange = (startDate, endDate, studentId, programId) => api.get(`${COURSE_ENDPOINT}/getSMUAbsenceInfoInDateRange`, { params: { startDate, endDate, studentId, programId } })

export const fetchSMUAbsenceInfoOfAllStudents = (year, semester, programId, startDate, endDate) => api.get(`${COURSE_ENDPOINT}/getSMUAbsenceInfoOfAllStudents`, { params: { startDate, endDate, programId, year, semester } })

export const fetchSetIsBusyInFirstLesson = (enrollmentId, isBusyInFirstLesson) => api.put(`/user/setIsBusyInFirstLesson?enrollmentId=${enrollmentId}&isBusyInFirstLesson=${isBusyInFirstLesson}`)

export const fetchAdmin = (year, semester) => Promise.all([
  api.get('dashboard/AdminGeneral', { params: {year, semester} }),
  api.get('dashboard/AdminPayment', { params: {year, semester} }),
  api.get('dashboard/BestOfCourse', { params: {year, semester} }),
])

export const fetchStaff = (year, semester) => Promise.all([
  api.get('dashboard/staff', { params: {year, semester} }),
  api.get('dashboard/StudioAnalyses', {params: {year, semester}}),
  api.get('dashboard/Notifications', { params: {year, semester} })
])

export const fetchStudentOfCourse = (courseId) => api.get(`${COURSE_ENDPOINT}/studentsOfCourse`, { params: { courseId } });
export const fetchCourseOfStudent = ({ id, year, semester, flags }) => api.get(`${COURSE_ENDPOINT}/coursesOfStudent`, { params: { studentId: id, year, semester, flags } });

export const deleteImportedUser = id => api.post(`user/deleteImportedUser/?id=${id}`)
export const deleteOldImportedUser = id => api.post(`user/deleteOldImportedUser/?id=${id}`)
export const fetchSendMail = () => api.post(SENDMAIL_ENDPOINT, null, { timeout: 0 })
export const fetchCreateMail = (body) => api.post(CREATEMAIL_ENDPOINT, body)
export const fetchCheckExistMessages = () => api.get('mailtemplate/checkexistmessages')

export const fetchStudentFromCourseAndProgram = (categoryType, courseIds, programIds, year, semester) => api.post('course/getstudentwithparentbycourseandprogram', { categoryType, courseIds, programIds, year, semester })

export const fetchStudentAbsentInFirstLessonBySemester = (year, semester) => api.get('course/getStudentAbsentInFirstLessonBySemester', { params: { year, semester } })

export const fetchStudentHasMoreThanTwoAbsenceInSemester = (year, semester) => api.get('course/getStudentHasMoreThanTwoAbsenceInSemester', { params: { year, semester } })

export const fetchNumberOfActiveStudent = ({ categoryType, forceRecreate, year, semester }) => api.get('user/countstudentsactiveandfamilies', { params: { categoryType, forceRecreate, year, semester } })
export const fetchTaskProgress = (type = 1) => api.get(`mailtemplate/taskprogress/?type=${type}`)

export const submitClassMissing = id => api.get(`attendance/adminsubmitclass/?classroomid=${id}`)
export const togglePaymentSplitApi = id => api.get(`payment/togglePaymentSplit/${id}`)

export const addExtraSalary = extra => api.post(`teacher/extrasalary`, extra)
export const deleteExtraSalary = id => api.delete(`teacher/extrasalary/${id}`)

export const getTeacherActive = ({ currentPage, pageSize }) => api.get(`teacher/getActiveTeachers`, { params: { currentPage, pageSize } })

export const getSecretKey = () => api.get('teacher/getSecretKey')

export const checkTokenApi = token => api.post('account/checktoken', token);

export const getEditStatus = (objectType, objectId) => {
  const userId = store.getState().common.userInfo.identity.id || 0
  return api.get(`/edit?userId=${userId}&objectType=${objectType}&objectId=${objectId}`)
}

export const overwriteEditStatus = (objectType, objectId) => {
  const userId = store.getState().common.userInfo.identity.id || 0
  return api.post(`/edit?userId=${userId}&objectType=${objectType}&objectId=${objectId}`)
}

export const deleteEditStatus = (objectType, objectId) => {
  const userId = store.getState().common.userInfo.identity.id || 0
  return api.delete(`/edit?userId=${userId}&objectType=${objectType}&objectId=${objectId}`)
}

export const updateSalaryMetaInfo = data => api.put(`teacher/salaryInfo`, data)

export const updateInvoiceInfo = data => api.post(`payment/updateInvoiceInfo`, data)

export const getSingleTeacherSalaryApi = (startDate, endDate) => {
  const teacherId = store.getState().common.userInfo.identity.id || 0
  return api.get(`teacher/singleTeacherSalary?teacherId=${teacherId}&startDate=${startDate}&endDate=${endDate}`)
}

export const confirmSalary = startDate => {
  const teacherId = store.getState().common.userInfo.identity.id || 0
  return api.put(`teacher/salaryInfo/confirm?teacherId=${teacherId}&startDate=${startDate}`)
}

export const submitSalaryError = startDate => {
  const teacherId = store.getState().common.userInfo.identity.id || 0
  return api.put(`teacher/salaryInfo/submitError?teacherId=${teacherId}&startDate=${startDate}`)
}

export const createSalaryTransaction = (startDate, endDate) => {
  return api.post(`fortnox/createSalaryTransaction`, { startDate, endDate }, { timeout: 0 })
}

export const sendToFortnoxInvoice = (year, semester) => {
  return api.post('fortnox/SendToFortnoxInvoice', { Year: year, Semester: semester }, { timeout: 0 })
}

export const sendConfirmSalaryRequest = data => api.post(`teacher/sendMailConfirmSalary`, data)
export const getEmployee = id => api.get(`fortnox/GetEmployee/${id}`)
export const getCustomer = id => api.get(`fortnox/GetCustomer/${id}`)
export const randomEmail = name => api.get(`user/randomemail/?name=${name}`)
export const syncToFortnoxEmployeeApi = data => api.post(`fortnox/SyncToFortnoxEmployee/`, data)
export const syncToFortnoxCusomterApi = data => api.post(`fortnox/SyncToFortnoxCustomer/`, data)

export const createAllPayment = (sem, year) => api.post(`payment/create-all/?semester=${sem}&year=${year}`, {}, { timeout: 0 })
export const getCreateAllPaymentStatus = () => api.get(`payment/create-all-status`)
export const cancelCreateAllPayment = () => api.post("payment/create-all-cancel")
export const addStudentToProgramFromCourse = (programId, studentId, courseId) => api.post(`program/addStudentFromCourse`, { programId, studentId, courseId })

export const updateAllPayment = (semester, year) => api.get(`payment/updateAllPayment/?semester=${semester}&year=${year}`, { timeout: 0 })
export const getUpdateAllPaymentStatus = () => api.get(`payment/update-all-status`)

export const getImportStudents = ({ currentPage, pageSize, searchString, sortBy, sortOrder, isDone }) => api.get(`user/importStudents`, { params: { currentPage, pageSize, searchString, sortBy, sortOrder, isDone } })

export const completeImport = (id, existedUserId) => api.post(`user/completeImport/?id=${id}&existedUserId=${existedUserId}`)

export const getTeacherFromSkill = ({ skillIds, currentPage, pageSize, teacherIds }) => api.get('teacher/getTeacherFromSkill', { params: { skillIds, currentPage, pageSize, teacherIds } })

export const updateOwnProfileUser = (data) => api.put('api/user', data)

export const deleteEmptyDraftFolder = () => api.get("mailtemplate/deleteEmptyDraft")

export const importStudent = data => api.post("user/importStudent", data)

export const getUrlLog = () => api.get("logs")

export const resetSalary = (teacherId, startDate) => api.post(`/teacher/salary/reset?teacherId=${teacherId}&startDate=${startDate}`)

export const createSubject = data => api.post(`/subject`, data)

export const getSubject = id => api.get(`/subject/${id}`)

export const updateSubject = data => api.put(`/subject`, data)

export const deleteSubject = id => api.delete(`/subject/${id}`)

export const getAllSubjects = ({
  currentPage,
  pageSize,
  searchString,
  sortBy,
  sortOrder
}) => api.get(`/subject/all`, {
  params: {
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
  }
})

export const markCommentAttendance = id => api.post(`/attendance/studentabsence/markcomment?id=${id}`)

export const rollbackConfirmationLesson = id => api.post(`/lession/rollbackconfirmationlesson?id=${id}`)

export const rollbackCancelledLesson = id => api.post(`/lession/rollbackcancelledlesson?id=${id}`)

export const getAllPDFTemplates = () => api.get(`/pdftemplate`)

export const updateInvoicePDFTemplate = data => api.post(`/pdftemplate/invoice`, data)

export const createAcademicClass = data => api.post(`/academicClass`, data);
export const updateAcademicClass = data => api.put(`/academicClass/${data.id}`, data);
export const removeAcademicClass = id => api.delete(`/academicClass/${id}`);

export const getAllAcademicClasses = ({
  currentPage,
  pageSize,
  searchString,
  sortBy,
  sortOrder
}) => api.get(`/academicClass`, {
  params: {
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
  }
})

export const getChatEnableStatus = () => api.get("/setting/getchatenablestatus")
export const getAllowStudentAndParentLoginStatus = () => api.get('/setting/getAllowStudentAndParentLoginStatus')
export const toggleChatMobile = () => api.put("/setting/togglechatmobile")
export const toggleAllowStudentAndParentLogin = () => api.put("/setting/toggleAllowStudentAndParentLogin")

export const getExcludeEmailThatContainSuffix = () => api.get('/setting/getExcluedEmailThatContainSuffix');
export const toggleExcludeEmailThatContainSuffix = () => api.put('/setting/toggleExcludeemailThatContainSuffix');

export const createAssistanceAttendance = ({ lessonId, assistanceIdentityId }) => api.put(`/attendance/createassistanceattendance?lessonId=${lessonId}&assistanceIdentityId=${assistanceIdentityId}`)

export const undoAssistanceAttendance = ({ lessonId, assistanceIdentityId }) => api.put(`/attendance/undoassistanceattendance?lessonId=${lessonId}&assistanceIdentityId=${assistanceIdentityId}`)

export const countActiveCourseStudent = ({ semester, year }) => api.get("/user/countactivecoursestudent", { params: { semester, year } })

export const countActiveProgramStudent = ({ semester, year }) => api.get("/user/countactiveprogramstudent", { params: { semester, year } })

export const getTeachingHours = ({
  currentPage,
  pageSize,
  year,
  semester,
  searchString = "",
  teacherIds = null
}) => api.get("/teacher/teachinghour", { params: { currentPage, pageSize, year, semester, searchString, teacherIds } })

export const deleteStudentPermanently = userId => api.delete(`/user/deleteUserPermanently/${userId}`)

export const fetchTeacherByCourseType = (year, semester, courseType) => api.get('teacher/getTeacherByCourseType', { params: { semester, year, courseType } } )

export const getLessionsCoincidesWithHoliday = (data) => api.post(`/course/getLessionsCoincidesWithHoliday`,data)

export const getLessonsByStudent = (studentId, startDate, endDate, sortBy, sortOrder, currentPage, pageSize) =>{
  return api.get(`lession/getLessionsByStudent`, { params: { studentId, startDate, endDate, sortBy, sortOrder, pageSize, currentPage } })
} 

export const getStudentsNoCourse = (searchString, year, semester, sortBy, sortOrder, currentPage, pageSize) =>{
  return api.get(`user/getStudentNoCourse`, { params: { searchString, year, semester, sortBy, sortOrder, pageSize, currentPage } })
}

export const getDropInStudents = (searchString, currentPage, pageSize, sortBy, sortOrder, year, semester) => {
  return api.get(`dropinstudents`, {params: {searchString, sortBy, sortOrder, pageSize, currentPage, year, semester}})
}