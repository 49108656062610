
export default (
    state = {
        courseType: [],
        totalCourseType: [],
        createStatus: 0,
        editStatus: 0,
        pageSize: localStorage.getItem("courseTypePageSize") ? Number(localStorage.getItem("courseTypePageSize")) : 30
    },
    action,
) => {
    switch (action.type) {
        case "GET_COURSE_TYPE_LIST":
            return {
                ...state,
                courseType: action.payload,
                currentPage: action.currentPage,
                searchString: action.searchString,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount,
            };
        case "GET_ALL_COURSE_TYPE":
            return {
                ...state,
                totalCourseType: action.payload,
            };

        case "CHANGE_PAGE_SIZE_TYPE":
            return {
                ...state,
                pageSize: action.payload
            }

        default:
            return state;
    }
};
