import React from "react"
import { connect } from "react-redux"
import { FormGroup, Label, Input, Row, Col, Button, Container } from "reactstrap"
import { withTranslation } from 'react-i18next'
import DatePicker from "react-datepicker";
import { Formik } from "formik"
import * as yup from "yup"
import moment from "moment"

import "react-datepicker/dist/react-datepicker.css";

import RequiredLabel from "../../../components/RequiredLabel"
import FormHeader from "../FormHeader"

import { setForm } from "../redux/action"
import history from "../../../util/history"

const Form1 = (props) => {
    const { t } = props

    const schema = yup.object({
        agree: yup.boolean().required(t("agreement_required")).nullable(),
        firstName: yup.string().required(t("first_name_required")),
        lastName: yup.string().required(t("last_name_required")),
        address: yup.string().required(t("address_requied")),
        zipCode: yup.string().required(t("zip_code_required")),
        city: yup.string().required(t("city_required")),
        phoneHome: yup.string(),
        phoneMobile: yup.string(),
        email: yup.string().email(t("email_invalid")).required(t("email_required")),
        confirmEmail: yup.mixed().test("matchEmail", t("email_not_match"), function () {
            return this.parent.email === this.parent.confirmEmail
        }),
        dateOfBirth: yup.string().required(t("date_of_birth_required")),
        under18: yup.boolean().required(t("please_select_under_18")).nullable(),
        gender: yup.boolean().required(t("please_select_gender")).nullable()
    })

    return <Formik
        initialValues={props.form1 && Object.keys(props.form1).length > 1 ? props.form1 : {
            agree: null,
            firstName: "",
            lastName: "",
            address: "",
            zipCode: "",
            city: "",
            phoneHome: "",
            phoneMobile: "",
            email: "",
            confirmEmail: "",
            dateOfBirth: "",
            under18: null,
            gender: null
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => {
            props.setForm(1, values, () => history.push("/form1/submit"))
        }}
    >
        {props => (
            <>
                <FormHeader />
                <Container className="pb-3">
                    <h3 className="font-weight-bold">{t("interest")}</h3>
                    <h4 className="font-weight-bold">{t("processing_of_personal_data")}</h4>
                    <p>{t("import_student_form1_para")}</p>
                    <hr />

                    <h5 className="font-weight-bold">{t("consents_accorsing")} <span className="text-danger">*</span></h5>
                    {props.touched.agree && props.errors.agree && <p className="text-danger">{props.errors.agree}</p>}

                    <FormGroup tag="fieldset">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="agree"
                                    checked={props.values.agree}
                                    onClick={() => props.setFieldValue("agree", true)}
                                />{' '}
                                <b>{t('i_agree')}</b>
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="agree"
                                    checked={props.values.agree === false}
                                    onClick={() => props.setFieldValue("agree", false)}
                                />{' '}
                                <b>{t('i_do_not_agree')}</b>
                            </Label>
                        </FormGroup>
                    </FormGroup>
                    <hr />

                    {props.values.agree && <>
                        <h4 className="font-weight-bold">{t("information_about_student")}</h4>
                        <hr />
                        <Row>
                            <Col md={12}>
                                <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("name")}</RequiredLabel>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Input
                                    placeholder={t("first_name")}
                                    name="firstName"
                                    value={props.values.firstName}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur("firstName")}
                                />
                                {props.touched.firstName && props.errors.firstName && <p className="text-danger">{props.errors.firstName}</p>}
                            </Col>
                            <Col md={6} className="mb-3">
                                <Input
                                    placeholder={t("last_name")}
                                    name="lastName"
                                    value={props.values.lastName}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur("lastName")}
                                />
                                {props.touched.lastName && props.errors.lastName && <p className="text-danger">{props.errors.lastName}</p>}
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("address")}</RequiredLabel>
                            </Col>
                            <Col md={12} className="mb-3">
                                <Input
                                    placeholder={t("street_address")}
                                    name="address"
                                    value={props.values.address}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur("address")}
                                />
                                {props.touched.address && props.errors.address && <p className="text-danger">{props.errors.address}</p>}
                            </Col>

                            <Col md={6} className="mb-3">
                                <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("Zip_code")}</RequiredLabel>

                                <Input
                                    placeholder={t("Zip_code")}
                                    name="zipCode"
                                    value={props.values.zipCode}
                                    onChange={props.handleChange}
                                />
                                {props.touched.zipCode && props.errors.zipCode && <p className="text-danger">{props.errors.zipCode}</p>}
                            </Col>
                            <Col md={6}>
                                <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("City")}</RequiredLabel>

                                <Input
                                    placeholder={t("City")}
                                    name="city"
                                    value={props.values.city}
                                    onChange={props.handleChange}
                                />
                                {props.touched.city && props.errors.city && <p className="text-danger">{props.errors.city}</p>}
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} className="mb-3">
                                <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("phone_home")}</Label>

                                <Input
                                    placeholder={t("phone_home")}
                                    name="phoneHome"
                                    value={props.values.phoneHome}
                                    onChange={props.handleChange}
                                />
                            </Col>

                            <Col md={6}>
                                <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("phone_mobile")}</Label>

                                <Input
                                    placeholder={t("phone_mobile")}
                                    name="phoneMobile"
                                    value={props.values.phoneMobile}
                                    onChange={props.handleChange}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("email")}</RequiredLabel>
                            </Col>

                            <Col md={6} className="mb-3">
                                <Input
                                    placeholder={t("email")}
                                    name="email"
                                    value={props.values.email}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur("email")}
                                />
                                {props.touched.email && props.errors.email && <p className="text-danger">{props.errors.email}</p>}
                            </Col>

                            <Col md={6} className="mb-3">
                                <Input
                                    placeholder={t("confirm_email")}
                                    name="confirmEmail"
                                    value={props.values.confirmEmail}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur("confirmEmail")}
                                />
                                {props.touched.confirmEmail && props.errors.confirmEmail && <p className="text-danger">{props.errors.confirmEmail}</p>}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={12}>
                                <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("date_of_birth")}</RequiredLabel>
                            </Col>
                            <Col md={6}>
                                <DatePicker
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Date of birth"
                                    name="dateOfBirth"
                                    customInput={<Input type="text" name="dateOfBirth" placeholder="YYYY-MM-DD" />}
                                    locale="en-gb"
                                    selected={props.values.dateOfBirth.trim() && moment(props.values.dateOfBirth).toDate()}
                                    value={props.values.dateOfBirth.trim() && moment(props.values.dateOfBirth).format("L")}
                                    onChange={e => {
                                        props.setFieldValue("dateOfBirth", moment(e).format("YYYY-MM-DD"))
                                    }}
                                />
                                {props.touched.dateOfBirth && props.errors.dateOfBirth && <p className="text-danger">{props.errors.dateOfBirth}</p>}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={12}>
                                <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("is_the_student_under_18")}</RequiredLabel>
                                {props.touched.under18 && props.errors.under18 && <p className="text-danger">{props.errors.under18}</p>}
                            </Col>
                            <Col md={12}>
                                <FormGroup tag="fieldset">
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="under18"
                                                onClick={() => props.setFieldValue("under18", true)}
                                                checked={props.values.under18}
                                            />{' '}
                                            <b>{t("yes")}</b>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="under18"
                                                onClick={() => props.setFieldValue("under18", false)}
                                                checked={props.values.under18 === false}
                                            />{' '}
                                            <b>{t("no")}</b>
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={12}>
                                <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("gender")}</RequiredLabel>
                                {props.touched.gender && props.errors.gender && <p className="text-danger">{props.errors.gender}</p>}
                            </Col>
                            <Col md={12}>
                                <FormGroup tag="fieldset">
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="gender"
                                                onClick={() => props.setFieldValue("gender", false)}
                                                checked={props.values.gender === false}
                                            />{' '}
                                            <b>{t("female")}</b>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="gender"
                                                onClick={() => props.setFieldValue("gender", true)}
                                                checked={props.values.gender}
                                            />{' '}
                                            <b>{t("male")}</b>
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                    </>}

                    {/* {props.values.agree === false && <>
                        <Row className="mb-3">
                            <Col md={12}>
                                <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>Nameless</Label>
                            </Col>
                            <Col md={12}>
                                <Input type="textarea" rows={5} />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={12}>
                                <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>Email</RequiredLabel>
                            </Col>

                            <Col md={6}>
                                <Input placeholder="Email" />
                            </Col>

                            <Col md={6}>
                                <Input placeholder="Confirm email" />
                            </Col>
                        </Row>
                        <hr />
                    </>} */}

                    <Button outline color="danger" style={{ padding: "8px 16px", fontSize: "1.1rem" }} onClick={props.handleSubmit}>{t("next")}</Button>
                </Container>
            </>
        )}
    </Formik>
}

const withMultiLanguage = withTranslation("common")(Form1)

const mapStateToProps = state => ({
    form1: state.importForm.form1
})

const mapDispatchToProps = {
    setForm
}

export default connect(mapStateToProps, mapDispatchToProps)(withMultiLanguage)