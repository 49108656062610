export default (
    state = {
        schema: {},
    },
    action,
) => {
    switch (action.type) {
        case "GET_SCHEMA":
            return {
                ...state,
                schema: action.payload,
            };
        default:
            return state;
    }
};
