export default (state = {
    form1: { goToSubmit: false },
    form2: {}
}, action) => {
    switch (action.type) {
        case "SET_FORM_1":
            return { ...state, form1: { ...state.form1, ...action.payload } }

        case "SET_FORM_2":
            return { ...state, form2: { ...state.form2, ...action.payload } }

        case "RESET_FORM_1":
            return { ...state, form1: { goToSubmit: false } }

        default:
            return state
    }
}