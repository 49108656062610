
export default (
  state = {
    family: [],
    familyInformation: {},
    totalFamily: [],
    createStatus: 0,
    editStatus: 0,
    pageSize: localStorage.getItem("familyPageSize") ? Number(localStorage.getItem("familyPageSize")) : 30,
    searchString: ''
  },
  action,
) => {
  switch (action.type) {
    case "GET_FAMILY_LIST":
      return {
        ...state,
        family: action.payload,
        currentPage: action.currentPage,
        searchString: action.searchString,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
      };
    case "GET_FAMILY_INFORMATION":
      return {
        ...state,
        familyInformation: action.payload,
      };
    case "GET_ALL_FAMILY":
      return {
        ...state,
        totalFamily: action.payload,
      };
    case "CREATE_FAMILY":
      return {
        ...state,
        createStatus: action.payload,
      };
    case "EDIT_FAMILY":
      return {
        ...state,
        familyInformation: action.payload,
      };
    case "DELETE_FAMILY":
      return {
        ...state,
        deleteStatus: action.payload,
      };

    case "CHANGE_PAGE_SIZE_FAMILY":
      return {
        ...state,
        pageSize: action.payload
      }

    case "SET_FAMILY_SEARCH_STRING":
      return {
        ...state,
        searchString: action.payload
      }

    default:
      return state;
  }
};
