export default (
  state = {
    lession: [],
  },
  action,
) => {
  switch (action.type) {
    case "GET_LESSION_LIST":
      return {
        ...state,
        lession: action.payload,
        currentPage: action.currentPage,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
      };
    case "CREATE_LESSION":
      return {
        ...state,
      };
    case "EDIT_LESSION":
      return {
        ...state,
      };
    case "DELETE_LESSION":
      return {
        ...state,
      };
    default:
      return state;
  }
};
