/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import {
    Button,
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
} from "reactstrap";


import history from "../../../util/history"
import { authenticator } from "../redux/actions"
import setAlert from "../../../components/Alert/action";
import { withTranslation } from 'react-i18next'
import LangSelected from '../../../components/Selected/LangSelected'
import SwitchButton from '../../../components/SwitchButton'

import AuthAndroidStep1 from '../../../assets/img/auth-android-step1.jpg'
import AuthAndroidStep3 from '../../../assets/img/auth-android-step3.jpg'
import AuthAndroidStep4 from '../../../assets/img/auth-android-step4.jpg'
import AuthAndroidStep5 from '../../../assets/img/auth-android-step5.jpg'

import AuthIOSStep1 from '../../../assets/img/auth-ios-step1.jpg'
import AuthIOSStep3 from '../../../assets/img/auth-ios-step3.jpg'
import AuthIOSStep4 from '../../../assets/img/auth-ios-step4.jpg'
import AuthIOSStep5 from '../../../assets/img/auth-ios-step5.jpg'

const Authentication = props => {
    const [isChecked, setIsChecked] = useState(false)

    const email = _.get(props, 'history.location.state.email')
    const isSent = _.get(props, 'history.location.state.isSent')

    const [codeAuth, setCodeAuth] = useState("")

    const onLoadImage = () => {
        const imgList = [AuthAndroidStep1,
            AuthAndroidStep3,
            AuthAndroidStep4,
            AuthAndroidStep5,
            AuthIOSStep1,
            AuthIOSStep3,
            AuthIOSStep4,
            AuthIOSStep5]
        imgList.map(img => {
            new Image().src = img;
        })
    }

    useEffect(() => {
        onLoadImage();

        if (!email) {
            history.push("/login")
        }
    }, [isChecked])




    const onSubmitAuth = e => {
        e.preventDefault();
        if (codeAuth.trim().length === 0) {
            props.setAlert("Email or password is not valid");
        } else {
            props.authenticator({ email, codeAuth });
        }
    };

    return <Form onSubmit={e => onSubmitAuth(e)} >
        <div className="app flex-row align-items-center authentication-wrapper">
            <Container >
                <CardGroup className='flex-column align-items-center'>
                    {/* <Card className="p-4">
                        <div className='position-relative'>
                            <div className='d-inline-block position-absolute' style={{
                                right: 0, top: 0
                            }}><LangSelected /></div>
                            <div onClick={() => setIsChecked(!isChecked)} className='d-inline-block position-absolute' style={{
                                left: 0, top: 0
                            }}>
                                <SwitchButton isChecked={isChecked} label={isChecked ? 'IOS' : 'Android'} />
                            </div>
                            <div className='text-center guide__title'>
                                <h1 className='guide__title-content'>
                                    {isChecked ? props.t("guide_to_login_for_ios") : props.t("guide_to_login_for_android")}
                                </h1>
                                <p className='guide__title-desc'>
                                    {`(${props.t("guide_title_desc")})`}
                                </p>
                            </div>
                            <div className="guide__body">
                                <div className='guide__step'>
                                    <span className='font-weight-bold'>{`${props.t('step_1')}: `}</span>
                                    {`${props.t("step_1_1")} ${props.t("step_1_2")} ${props.t("step_1_3")}`}
                                    <span className='font-weight-bold'> {props.t("step_1_4")}</span>.
                                                        <p>{props.t("step_1_5")}
                                        <span className='font-weight-bold'> {props.t("step_1_4")}</span>.</p>
                                    <div className='guide__step-img'><img src={isChecked ? AuthIOSStep1 : AuthAndroidStep1} alt="Step 1" /></div>
                                </div>
                                <div className='guide__step'>
                                    <span className='font-weight-bold'>{`${props.t("step_2")}: `}</span>
                                    {props.t("step_2_1")}
                                    <p className='m-0'>{isChecked ? props.t("step_2_2_ios") : props.t("step_2_2_android")}</p>
                                    <p>
                                        <a href={isChecked ? props.t("step_2_3_ios") : props.t("step_2_3_android")}>
                                            {isChecked ? props.t("step_2_3_ios") : props.t("step_2_3_android")}
                                        </a>
                                    </p>
                                </div>
                                <div className='guide__step'>
                                    <span className='font-weight-bold'>{`${props.t("step_3")}: `}</span>
                                    {isChecked ? props.t("step_3_1_ios") : props.t("step_3_1_android")}
                                    <div className='guide__step-img'><img src={isChecked ? AuthIOSStep3 : AuthAndroidStep3} alt="Step 3" /></div>
                                </div>
                                <div className='guide__step'>
                                    <span className='font-weight-bold'>{`${props.t("step_4")}: `}</span>
                                    {isChecked ? props.t("step_4_1_ios") : props.t("step_4_1_android")}
                                    <span className='font-weight-bold'> {props.t("step_4_2")}</span>
                                    <div className='guide__step-img'><img src={isChecked ? AuthIOSStep4 : AuthAndroidStep4} alt="Step 4" /></div>
                                </div>
                                <div className='guide__step'>
                                    <span className='font-weight-bold'>{`${props.t("step_5")}: `}</span>
                                    {props.t("step_5_1")}
                                    <div className='guide__step-img'><img src={isChecked ? AuthIOSStep5 : AuthAndroidStep5} alt="Step 5" /></div>
                                    <i className={`guide__step-arrow--down ${isChecked ? 'guide__step-ios' : ''}`}></i>
                                    <i className='guide__step-arrow--right'></i>
                                </div>
                            </div>

                        </div>
                    </Card> */}
                    <Card className="p-4 mt-4 mb-5">
                        <CardBody className='p-0'>
                            <Row>
                                <Col xs='12'>
                                    <h3 className="mb-3">{props.t("twofa_authentication")}</h3>
                                    {isSent && <p className="mb-2">
                                        {props.t("please_check_your_email_to_complete_2FA")}
                                    </p>}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="7" className="">
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-lock" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            value={codeAuth}
                                            onChange={e => setCodeAuth(e.target.value)}
                                            placeholder={props.t("verification_token")}
                                            required
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs="5">
                                    <Button color="primary" className="px-4" type="submit">
                                        {props.t("login")}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </CardGroup>
            </Container>
        </div >
    </Form >
}

const AuthenticationHOC = withTranslation("common")(Authentication)

const mapDispatchToProps = {
    authenticator,
    setAlert
}

export default connect(null, mapDispatchToProps)(AuthenticationHOC)