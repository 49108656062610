import moment from "moment"
const initState = {
    data: [],
    searchString: '',
    year: moment().year(),
    semester: {label: "VT", value: "VT"},
    pageCount: 0
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_STUDENTS_NO_COURSE':
            return {
                ...state,
                data: action.payload ? action.payload : [],
                currentPage: action.currentPage,
                searchString: action.searchString,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount,
            }
        case 'SET_STUDENT_NO_COURSE_SEARCH_STRING':
            return {
                ...state,
                searchString: action.payload,
            }
        case 'SET_STUDENT_NO_COURSE_SEMESTER':
            return {
                ...state,
                semester: action.payload,
            }
        case 'SET_STUDENT_NO_COURSE_YEAR':
            return {
                ...state,
                year: action.payload,
            }
        default:
            return state
    }
}

export default reducer