import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { withTranslation } from 'react-i18next'
import { handleDynamicError } from "../../util/helper"

import setModal from "./action";

class AppAlert extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  delay = 0;

  componentWillReceiveProps(newProps) {
    if (newProps.modal.message.length > 0) {
      this.setState({ visible: true });
    }
  }

  onDismiss = () => {
    this.setState({ visible: false });
    this.props.setModal({ message: "", cb: null });
  };

  cb = () => {
    this.onDismiss();
    this.props.modal.cb();
  };

  render() {
    const { t } = this.props
    const message = Array.isArray(this.props.modal.message)
      ? handleDynamicError(this.props.modal.message.map(item => ({ value: item })))
      : this.props.modal.message

    return this.props.modal.message.length > 0 ? (
      <>
        <Modal
          isOpen={this.state.visible}
          toggle={() => {
            this.onDismiss()
            if (this.props.modal.dismiss) {
              this.props.modal.dismiss()
            }
          }}
          className={`modal-${this.props.modal.type}` || "modal-primary"}
        >
          <ModalHeader toggle={() => {
            this.onDismiss()
            if (this.props.modal.dismiss) {
              this.props.modal.dismiss()
            }
          }}>{t('alert')}</ModalHeader>
          <ModalBody>
            {Array.isArray(this.props.modal.message) &&
              message.map((e, i) => <p key={`${i}`}>{typeof (e) === 'object' ? t(e.errCode, e.errObj) : t(e)}</p>)}
            {typeof this.props.modal.message === "string" && <p>{t(this.props.modal.message)}</p>}
          </ModalBody>
          {!this.props.modal.noButton && <ModalFooter>
            {this.props.modal.cb && (
              <>
                <Button color={this.props.modal.type || "primary"} onClick={this.cb}>
                  {t(this.props.modal.label) || t('yes')}
                </Button>
                <Button color="secondary" onClick={() => {
                  this.onDismiss()
                  if (this.props.modal.dismiss) {
                    this.props.modal.dismiss()
                  }
                }}>
                  {this.props.modal.cancelText ? t(this.props.modal.cancelText) : t('cancel')}
                </Button>
              </>
            )}
            {this.props.modal.cb1 && this.props.modal.cb2 && (
              <>
                <Button
                  color={this.props.modal.type1 || "primary"}
                  onClick={() => {
                    this.onDismiss();
                    this.props.modal.cb1();
                  }}
                >
                  {t(this.props.modal.label1)}
                </Button>
                <Button
                  color={this.props.modal.type2 || "primary"}
                  onClick={() => {
                    this.onDismiss();
                    this.props.modal.cb2();
                  }}
                >
                  {t(this.props.modal.label2)}
                </Button>
                {!this.props.modal.hideCancel && <Button color="secondary" onClick={() => {
                  this.onDismiss()
                  if (this.props.modal.dismiss) {
                    this.props.modal.dismiss()
                  }
                }}>
                  {this.props.modal.cancelText ? t(this.props.modal.cancelText) : t('cancel')}
                </Button>}
              </>
            )}
            {!this.props.modal.cb && !this.props.modal.cb1 && !this.props.modal.cb3 && !this.props.modal.cb2 && (
              <Button color={this.props.modal.type || "primary"} onClick={this.onDismiss}>
                {t('Close')}
              </Button>
            )}
            {
              this.props.modal.cb3 && (
                <Button
                  color={this.props.modal.type || "primary"}
                  onClick={() => {
                    this.onDismiss();
                    this.props.modal.cb3();
                  }}>
                  Ok
                </Button>
              )
            }
          </ModalFooter>}
        </Modal>
      </>
    ) : null;
  }
}

const withMultiLanguage = withTranslation('common')(AppAlert)

const mapStateToProps = state => ({
  modal: state.modal.modal,
});

const mapDispatchToProps = dispatch => ({
  setModal: modal => dispatch(setModal(modal)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMultiLanguage);
