import _ from "lodash";

export default (
    state = {
        teacherSalary: [],
        allReady: false,
        teacherSingleSalary: [],
        pageSize: localStorage.getItem("teacherSalaryPageSize") ? Number(localStorage.getItem("teacherSalaryPageSize")) : 30,
        status: { value: 'new', label: 'New' },
        type: { value: undefined, label: "All" },
        salaryViaInvoice: { value: undefined, label: 'All' },
        searchString: "",
        totalTotalLessons: 0,
        totalTotalAbsence: 0,
        totalTotalAssistance: 0,
        totalTotalSubstituted: 0,
        totalTotalExtraWork: 0,
        totalTotalSalary: 0,
    },
    action,
) => {
    switch (action.type) {
        case "GET_TEACHER_SALARY_LIST":
            return {
                ...state,
                teacherSalary: _.get(action, 'payload.salaries', []),
                allReady: _.get(action, 'payload.allReady', false),
                currentPage: action.currentPage,
                searchString: action.searchString,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount,
                status: action.status,
                type: action.filterType,
                salaryViaInvoice: action.salaryViaInvoice,
            };
        case "GET_TOTAL_SALARY":
            return {
                ...state,
                totalTotalLessons: _.get(action, 'payload.totalTotalLessons', 0),
                totalTotalAbsence: _.get(action, 'payload.totalTotalAbsence', 0),
                totalTotalAssistance: _.get(action, 'payload.totalTotalAssistance', 0),
                totalTotalSubstituted: _.get(action, 'payload.totalTotalSubstituted', 0),
                totalTotalExtraWork: _.get(action, 'payload.totalTotalExtraWork', 0),
                totalTotalSalary: _.get(action, 'payload.totalTotalSalary', 0),
            };
        case "GET_SINGLE_TEACHER_SALARY_LIST":
            return {
                ...state,
                teacherSingleSalary: _.get(action, 'payload.salaries', []),
            };

        case "CHANGE_PAGE_SIZE_TEACHER_SALARY":
            return {
                ...state,
                pageSize: action.payload
            }
        default:
            return state;
    }
};
