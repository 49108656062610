export default (
    state = {
        category: [],
        totalCategory: [],
        pageSize: 10,
        currentCategory: 0
    },
    action
) => {
    // console.log(action)
    switch (action.type) {
        case "GET_CATEGORY_LIST":
            return {
                ...state,
                category: action.payload,
                currentPage: action.currentPage,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount
            }

        case "GET_TOTAL_CATEGORY":
            return {
                ...state,
                totalCategory: action.payload
            }

        case "CHANGE_PAGE_SIZE_CATEGORY":
            return {
                ...state,
                pageSize: action.payload
            }

        case "CHANGE_CURRENT_CATEGORY":
            return {
                ...state,
                currentCategory: action.payload
            }

        default:
            return state
    }
}