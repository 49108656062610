import React, { useState } from "react"
import { connect } from "react-redux"
import { Row, Col, Label, Input, Button } from "reactstrap"
import { Formik } from "formik"
import * as yup from "yup"
import _ from "lodash"

import { setForm } from "../redux/action"
import history from "../../../util/history"

const btnStyle = { padding: "8px 16px", fontSize: "1.1rem" }

const Under18Form = props => {
    const onBack = () => props.setForm(1, { goToSubmit: false }, () => history.push("/form1"))

    const { t } = props

    const schema = yup.object({
        parentFirstName: yup.string(),
        parentLastName: yup.string(),
        phone1: yup.string(),
        phone2: yup.string(),
        phone3: yup.string(),
        phone4: yup.string(),
        parentEmail: yup.string().email("email_invalid"),
        confirmParentEmail: yup.mixed().test("matchEmail", t("email_not_match"), function () {
            return this.parent.parentEmail === this.parent.confirmParentEmail
        }),
    })

    return <Formik
        initialValues={{
            parentFirstName: _.get(props, "form1.parentFirstName", ""),
            parentLastName: _.get(props, "form1.parentLastName", ""),
            phone1: _.get(props, "form1.phone1", ""),
            phone2: _.get(props, "form1.phone2", ""),
            phone3: _.get(props, "form1.phone3", ""),
            phone4: _.get(props, "form1.phone4", ""),
            parentEmail: _.get(props, "form1.parentEmail", ""),
            confirmParentEmail: _.get(props, "form1.confirmParentEmail", "")
        }}
        validationSchema={schema}
        onSubmit={async (values, actions) => {
            console.log(values)
            values.goToSubmit = true
            await props.setForm(1, values)
        }}
    >
        {
            props => (<>
                <h3 className="font-weight-bold">{t("interest")}</h3>
                <h4 className="font-weight-bold">{t("information_about_the_goalman")}</h4>
                <p>{t("if_student_under_18")}</p>
                <hr />
                <Row>
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("name")}</Label>
                    </Col>
                    <Col md={6} className="mb-3">
                        <Input
                            placeholder={t("first_name")}
                            name="parentFirstName"
                            value={props.values.parentFirstName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur("parentFirstName")}
                        />
                        {props.touched.parentFirstName && props.errors.parentFirstName && <p className="text-danger">{props.errors.parentFirstName}</p>}
                    </Col>
                    <Col md={6} className="mb-3">
                        <Input
                            placeholder={t("last_name")}
                            name="parentLastName"
                            value={props.values.parentLastName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur("parentLastName")}
                        />
                        {props.touched.parentLastName && props.errors.parentLastName && <p className="text-danger">{props.errors.parentLastName}</p>}
                    </Col>
                </Row>

                <Row>
                    <Col md={6} className="mb-3">
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("phone1_mobile")}</Label>

                        <Input
                            placeholder={t("phone_mobile_1")}
                            name="phone1"
                            value={props.values.phone1}
                            onChange={props.handleChange}
                        />
                    </Col>

                    <Col md={6} className="mb-3">
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("phone2_mobile")}</Label>

                        <Input
                            placeholder={t("phone_mobile_2")}
                            name="phone2"
                            value={props.values.phone2}
                            onChange={props.handleChange}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={6} className="mb-3">
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("phone3_home")}</Label>

                        <Input
                            placeholder={t("phone_home_3")}
                            name="phone3"
                            value={props.values.phone3}
                            onChange={props.handleChange}
                        />
                    </Col>

                    <Col md={6} className="mb-3">
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("phone4_home")}</Label>

                        <Input
                            placeholder={t("phone_home_4")}
                            name="phone4"
                            value={props.values.phone4}
                            onChange={props.handleChange}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("email")}</Label>
                    </Col>

                    <Col md={6} className="mb-3">
                        <Input
                            placeholder={t("email")}
                            name="parentEmail"
                            value={props.values.parentEmail}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur("parentEmail")}
                        />
                        {props.touched.parentEmail && props.errors.parentEmail && <p className="text-danger">{props.errors.parentEmail}</p>}
                    </Col>

                    <Col md={6} className="mb-3">
                        <Input
                            placeholder={t("confirm_email")}
                            name="confirmParentEmail"
                            value={props.values.confirmParentEmail}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur("confirmParentEmail")}
                        />
                        {props.touched.confirmParentEmail && props.errors.confirmParentEmail && <p className="text-danger">{props.errors.confirmParentEmail}</p>}
                    </Col>
                </Row>
                <hr />

                <Button
                    outline
                    color="danger"
                    className="mr-3"
                    style={btnStyle}
                    onClick={onBack}
                >
                    {t("previous")}
                </Button>
                <Button outline color="danger" style={btnStyle} onClick={props.handleSubmit}>{t("next")}</Button>
            </>)
        }
    </Formik>
}

const mapStateToProps = state => ({
    form1: state.importForm.form1
})

const mapDispatchToProps = {
    setForm
}

export default connect(mapStateToProps, mapDispatchToProps)(Under18Form)