const initState = {
    vt: { 
        id: 0,
        name: 'VT',
        startTime: new Date(2020, 0, 1),
        endTime: new Date(2020, 6, 31)
    },
    ht: {
        id: 0,
        name: 'HT',
        startTime: new Date(2020, 7, 1),
        endTime: new Date(2020, 11, 31)
    },
    sm: {
        id: 0,
        name: 'ST',
        startTime: new Date(2020, 6, 1),
        endTime: new Date(2020, 6, 31)
    }
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_SEMESTERS':
            return {
                ...state,
                vt: action.payload.vt,
                ht: action.payload.ht,
                sm: action.payload.sm
            }
        default:
            return state
    }
}

export default reducer