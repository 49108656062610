import { importStudent } from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"
import history from "../../../util/history"

export const setForm = (formNumber, data, cb) => async dispatch => {
    const type = `SET_FORM_${formNumber}`

    dispatch({
        type,
        payload: data
    })

    if (cb) cb()
}

export const resetForm = (formNumber, cb) => async dispatch => {
    const type = `RESET_FORM_${formNumber}`

    dispatch({
        type
    })

    if (cb) cb()
}

export const importData = (data) => async dispatch => {
    dispatch(setLoading(true))

    try {
        await importStudent(data)
        const id = data["form_id"]
        history.push(`/form/${id}`)
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.message
        }))
    }

    dispatch(setLoading(false))
}