import localStore from "localStorage";

export default (
    state = {
        pageSize: { label: 10, value: 10 },
        setupTab: "#skill",
        tableZone: window.innerWidth,
        lang: localStore.getItem("lang") || "en",
        userInfo: null,
    },
    action,
) => {
    switch (action.type) {
        case "SET_PAGE_SIZE":
            return {
                ...state,
                pageSize: action.payload
            };
        case "SET_USER_INFO":
            return {
                ...state,
                userInfo: action.userInfo
            };
        case "SAVE_SETUP_TAB":
            return {
                ...state,
                setupTab: action.payload
            };
        case "SET_TABLE_ZONE_WIDTH":
            return {
                ...state,
                tableZone: action.payload - 16
            };
        case "LANGUAGE_CHANGE":
            return {
                ...state,
                lang: action.payload,
            };
        default:
            return state;
    }
};
