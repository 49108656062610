import React from 'react';
import { connect } from "react-redux";

const Spinner = ({ isLoading }) => {
    return (isLoading ? (
        <div className="fixed-loader-container">
            <div className="main-loader"/>
        </div>
    ) : null);
};

const mapStateToProps = state => ({
  isLoading: state.loading.isLoading
});

export default connect(
  mapStateToProps,
  null,
)(Spinner);