import React from "react"
import { connect } from "react-redux"
import { Formik } from "formik"
import * as yup from "yup"
import { Row, Col, Label, Input, Button, FormGroup, Container } from "reactstrap"
import { withTranslation } from 'react-i18next'
import moment from "moment"
import DatePicker from "react-datepicker"

import RequiredLabel from "../../../components/RequiredLabel"
import FormHeader from "../FormHeader"

import history from "../../../util/history"
import { formatImportStudentDataForm2 } from "../../../util/helper"
import { resetForm, importData } from "../redux/action"

const btnStyle = { padding: "8px 16px", fontSize: "1.1rem" }

const Form2 = props => {
    const { t } = props
    const schema = yup.object({
        agree: yup.boolean().required(t("agreement_required")).nullable(),
        firstName: yup.string().required(t("first_name_required")),
        lastName: yup.string().required(t("last_name_required")),
        email: yup.string().email(t("email_invalid")).required(t("email_required")),
        phone: yup.string().required(t("phone_required")),
        address: yup.string(),
        address2: yup.string(),
        zipCode: yup.string(),
        city: yup.string(),
        country: yup.string(),
        dateOfBirth: yup.string().required(t("date_of_birth_required")),
        secondaryEducation: yup.string(),
        postSecondaryEducation: yup.string(),
        briefPreviousExp: yup.string().required(t("previous_exp_required")),
        singExp: yup.string(),
        theaterExp: yup.string(),
        danceExp: yup.string(),
        anythingElse: yup.string()
    })

    const submitImportStudent = async data => {
        const importData = formatImportStudentDataForm2(data)
        await props.importData(importData)
    }

    return <Formik
        initialValues={props.form1 && Object.keys(props.form1).length > 1 ? props.form1 : {
            agree: null,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            address2: "",
            zipCode: "",
            city: "",
            country: "",
            dateOfBirth: "",
            secondaryEducation: "",
            postSecondaryEducation: "",
            briefPreviousExp: "",
            singExp: "",
            theaterExp: "",
            danceExp: "",
            anythingElse: ""
        }}
        validationSchema={schema}
        onSubmit={async (values, actions) => {
            await submitImportStudent(values)
            actions.resetForm()
        }}
    >
        {props => (<>
            <FormHeader />
            <Container className="pb-3">
                <h3>{t("register_for_audition")}</h3>
                <p style={{ fontSize: "1.1rem" }}>{t("fill_in_your_info")}</p>
                <hr />

                <Row>
                    <Col md={12}>
                        <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("name")}</RequiredLabel>
                    </Col>
                    <Col md={6} className="mb-3">
                        <Input
                            placeholder={t("first_name")}
                            name="firstName"
                            value={props.values.firstName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur("firstName")}
                        />
                        {props.touched.firstName && props.errors.firstName && <p className="text-danger">{props.errors.firstName}</p>}
                    </Col>
                    <Col md={6} className="mb-3">
                        <Input
                            placeholder={t("last_name")}
                            name="lastName"
                            value={props.values.lastName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur("lastName")}
                        />
                        {props.touched.lastName && props.errors.lastName && <p className="text-danger">{props.errors.lastName}</p>}
                    </Col>
                </Row>

                <Row>
                    <Col md={6} className="mb-3">
                        <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("email")}</RequiredLabel>

                        <Input
                            placeholder={t("email")}
                            name="email"
                            value={props.values.email}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur("email")}
                        />
                        {props.touched.email && props.errors.email && <p className="text-danger">{props.errors.email}</p>}
                    </Col>

                    <Col md={6} className="mb-3">
                        <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("phone")}</RequiredLabel>

                        <Input
                            placeholder={t("phone")}
                            name="phone"
                            value={props.values.phone}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur("phone")}
                        />
                        {props.touched.phone && props.errors.phone && <p className="text-danger">{props.errors.phone}</p>}
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("address")}</Label>
                    </Col>
                    <Col md={12} className="mb-3">
                        <Input
                            placeholder={t("street_address")}
                            name="address"
                            value={props.values.address}
                            onChange={props.handleChange}
                        />
                    </Col>

                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("address")} 2</Label>
                    </Col>

                    <Col md={12} className="mb-3">
                        <Input
                            placeholder={`${t("street_address")} 2`}
                            name="address2"
                            value={props.values.address2}
                            onChange={props.handleChange}
                        />
                    </Col>

                    <Col md={6}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("Zip_code")}</Label>

                        <Input
                            className="mb-3"
                            placeholder={t("Zip_code")}
                            name="zipCode"
                            value={props.values.zipCode}
                            onChange={props.handleChange}
                        />
                    </Col>

                    <Col md={6}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("City")}</Label>

                        <Input
                            className="mb-3"
                            placeholder={t("City")}
                            name="city"
                            value={props.values.city}
                            onChange={props.handleChange}
                        />
                    </Col>

                    <Col md={6} className="mb-3">
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("Country")}</Label>
                        <Input
                            placeholder={t("Country")}
                            name="country"
                            value={props.values.country}
                            onChange={props.handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("date_of_birth")}</RequiredLabel>
                        <DatePicker

                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="YYYY-MM-DD"
                            name="dateOfBirth"
                            customInput={<Input type="text" name="dateOfBirth" placeholder="YYYY-MM-DD" />}
                            locale="en-gb"
                            selected={props.values.dateOfBirth.trim() && moment(props.values.dateOfBirth).toDate()}
                            value={props.values.dateOfBirth.trim() && moment(props.values.dateOfBirth).format("L")}
                            onChange={e => {
                                props.setFieldValue("dateOfBirth", moment(e).format("YYYY-MM-DD"))
                            }}
                        />
                        {props.touched.dateOfBirth && props.errors.dateOfBirth && <p className="text-danger">{props.errors.dateOfBirth}</p>}
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("secondary_education")}</Label>
                        <p>{t("secondary_education_des")}</p>
                    </Col>
                    <Col md={12}>
                        <Input
                            type="textarea"
                            rows={5}
                            value={props.values.secondaryEducation}
                            maxLength={200}
                            name="secondaryEducation"
                            onChange={props.handleChange}
                        />
                        <p>{t("200_maximum_character", { count: props.values.secondaryEducation.length })}</p>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("post_secondary_education")}</Label>
                        <p>{t("post_secondary_education_des")}</p>
                    </Col>
                    <Col md={12}>
                        <Input
                            type="textarea"
                            rows={5}
                            value={props.values.postSecondaryEducation}
                            maxLength={200}
                            name="postSecondaryEducation"
                            onChange={props.handleChange}
                        />
                        <p>{t("200_maximum_character", { count: props.values.postSecondaryEducation.length })}</p>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("briefly_pre_exp")}</RequiredLabel>
                        <p>{t("briefly_pre_exp_des")}</p>
                    </Col>
                    <Col md={12}>
                        <Input
                            type="textarea"
                            rows={5}
                            value={props.values.briefPreviousExp}
                            maxLength={500}
                            name="briefPreviousExp"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur("briefPreviousExp")}
                        />
                        <p>{t("500_maximum_character", { count: props.values.briefPreviousExp.length })}</p>
                        {props.touched.briefPreviousExp && props.errors.briefPreviousExp && <p className="text-danger">{props.errors.briefPreviousExp}</p>}
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("sing_exp")}</Label>
                        <p>{t("sing_exp_des")}</p>
                    </Col>
                    <Col md={12}>
                        <Input
                            type="textarea"
                            rows={5}
                            value={props.values.singExp}
                            maxLength={1000}
                            name="singExp"
                            onChange={props.handleChange}
                        />
                        <p>{t("1000_maximum_character", { count: props.values.singExp.length })}</p>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("theater_exp")}</Label>
                        <p>{t("theater_exp_des")}</p>
                    </Col>
                    <Col md={12}>
                        <Input
                            type="textarea"
                            rows={5}
                            value={props.values.theaterExp}
                            maxLength={1000}
                            name="theaterExp"
                            onChange={props.handleChange}
                        />
                        <p>{t("1000_maximum_character", { count: props.values.theaterExp.length })}</p>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("dance_exp")}</Label>
                        <p>{t("dance_exp_des")}</p>
                    </Col>
                    <Col md={12}>
                        <Input
                            type="textarea"
                            rows={5}
                            value={props.values.danceExp}
                            maxLength={1000}
                            name="danceExp"
                            onChange={props.handleChange}
                        />
                        <p>{t("1000_maximum_character", { count: props.values.danceExp.length })}</p>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("anything_else")}</Label>
                        <p>{t("anything_else_des")}</p>
                    </Col>
                    <Col md={12}>
                        <Input
                            type="textarea"
                            rows={5}
                            value={props.values.anythingElse}
                            maxLength={1000}
                            name="anythingElse"
                            onChange={props.handleChange}
                        />
                        <p>{t("1000_maximum_character", { count: props.values.anythingElse.length })}</p>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("portrait_pic")}</Label>
                        <p>{t("portrait_pic_des")}</p>
                        <Input type="file" />
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <RequiredLabel className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("personal_info")}</RequiredLabel>

                        <p>{t("personal_info_des")}</p>

                        <FormGroup tag="fieldset">
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="agree"
                                        checked={props.values.agree}
                                        onClick={() => props.setFieldValue("agree", true)}
                                    />{' '}
                                    <b>{t('i_agree')}</b>
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="agree"
                                        checked={props.values.agree === false}
                                        onClick={() => props.setFieldValue("agree", false)}
                                    />{' '}
                                    <b>{t('i_do_not_agree')}</b>
                                </Label>
                            </FormGroup>
                        </FormGroup>
                        {props.touched.agree && props.errors.agree && <p className="text-danger">{props.errors.agree}</p>}

                    </Col>
                </Row>
                <hr />

                <Button outline color="danger" style={btnStyle} onClick={props.handleSubmit}>{t("send")}</Button>
            </Container>
        </>)}
    </Formik>
}

const withMultiLanguage = withTranslation("common")(Form2)

const mapStateToProps = state => ({
    form2: state.importForm.form2
})

const mapDispatchToProps = {
    resetForm, importData
}

export default connect(mapStateToProps, mapDispatchToProps)(withMultiLanguage)

