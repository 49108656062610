export default (
  state = {
    studentEnrollment: [],
    payment: {},
    student: [],
    pageSize: 10
  },
  action,
) => {
  switch (action.type) {
    case "GET_STUDENT_ENROLLMENT_LIST":
      return {
        ...state,
        studentEnrollment: action.payload,
        currentPage: action.currentPage,
        searchString: action.searchString,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
      };
    case "REMOVE_STUDENT_ENROLLMENT_INFORMATION":
      return {
        studentEnrollment: [],
        payment: {},
      };
    case "GET_COURSE_OF_PROGRAM_LIST": {
      return {
        ...state,
        studentEnrollment: [...state.studentEnrollment].map(m => {
          if (m.program && m.programId === action.programId && m.studentId === action.studentId) {
            m.program.courses = action.payload;
          }
          return m;
        }),
      };
    }
    case "GET_PAYMENT_INFORMATION":
      return {
        ...state,
        payment: action.payload,
      };
    case "GET_STUDENT_ENROLLMENT":
      return {
        student: action.payload,
      };

    case "CHANGE_PAGE_SIZE_STUDENT_ENROLLMENT":
      return {
        ...state,
        pageSize: action.payload
      }

    default:
      return state;
  }
};
