import localStorage from "localStorage";
import moment from "moment";
import store from '../../../store'

import history from "../../../util/history";
import setLoading from "../../../components/Spinner/action";
import { api, LOGIN_ENDPOINT, AUTHEN_ENDPOINT } from "../../../util/api";
import setAlert from "../../../components/Alert/action";
import setModal from "../../../components/Modal/action";
import { ACCESS_TOKEN, USER_EMAIL } from "../../../util/Constant";
import { getUserInfo } from "../../Common/action";
import { isTeacherOrAssistanceOnly, isParentOrStudent } from '../../../util/Common'

export const login = (Email, Password) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const response = await api.post(LOGIN_ENDPOINT, {
      Email,
      Password,
    });

    const { data } = response

    const authLoginStatus = {
      success: ["Fill auth code to log in", "Auth code is sent"],
      failed: ["Failed in sending auth code"],
      inactive: ["account is inactive"],
    }

    if (authLoginStatus.failed.includes(data)) {
      dispatch(setAlert({
        type: 'danger',
        message: `${data}. Please try again`
      }))
      dispatch(setLoading(false))

      return
    }

    if (authLoginStatus.inactive.includes(data)) {
      dispatch(setModal({
        type: 'danger',
        message: "account_is_inactive"
      }))
      dispatch(setLoading(false))

      return
    }

    if (authLoginStatus.success.includes(data)) {
      const isSent = data === authLoginStatus.success[1]
      history.push("/authenticator", { email: Email, isSent })
      dispatch(setLoading(false))
      return
    }

    localStorage.setItem(ACCESS_TOKEN, response.data);
    localStorage.setItem(USER_EMAIL, Email);
    await dispatch(getUserInfo())
    if (isTeacherOrAssistanceOnly) {
      history.push("/my-salary")
    } else {
      history.push("/abscense-report/missing-report");
    }
    // if(isParentOrStudent) {
    //   history.push("/info")
    // }
  } catch (error) {
    dispatch(
      setModal({
        message: "username_or_password_incorrect",
        type: "danger",
      }),
    );
  }
  dispatch(setLoading(false));
};

export const authenticator = ({ email, codeAuth }) => async dispatch => {
  dispatch(setLoading(true))
  try {
    const res = await api.post(AUTHEN_ENDPOINT, { email, codeAuth })
    localStorage.setItem(ACCESS_TOKEN, res.data);
    localStorage.setItem(USER_EMAIL, email);
    await dispatch(getUserInfo())
    if (isTeacherOrAssistanceOnly) {
      history.push("/my-salary")
    } else {
      history.push("/abscense-report/missing-report");
    }
  } catch (err) {
    dispatch(
      setModal({
        message: "Wrong token authentication",
        type: "danger",
      }),
    );
  }
  dispatch(setLoading(false))
}

export const logout = () => dispatch => {
  localStorage.removeItem(ACCESS_TOKEN);
  history.push("/login");
  dispatch(
    setAlert({
      message: "log_out_success",
      type: "success",
    }),
  );
};