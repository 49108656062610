import moment from "moment"

export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const EXPIRE_TIME = 'EXPIRE_TIME';
export const USER_EMAIL = 'USER_EMAIL';
export const EXPIRE_TIME_DEFAULT = 24 * 60 * 60 * 1000;
export const API_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    ERROR: 'ERROR'
}
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_HOUR_MINUTE_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';

export const MAIL_CATE_GENERAL = 1
export const MAIL_CATE_COURSE_INFO = 2
export const MAIL_CATE_INVOICE = 3
export const MAIL_CATE_NEW_USER = 4
export const TEACHER_SALARY_EMAIL = 5
export const MAIL_CATE_STUDENTS_ABSENT_FIRST_LESSON = 8
export const MAIL_CATES_STUDENTS_ABSENT_3_TIMES = 7
export const MAIL_CATE_COURSE_INFO_FOR_TEACHER = 6

export const EXPIRE_TIME_IN_DAYS_INVOICE = 3

export const DOCUMENT_TITLE = {
    dashboard: "dashboard_lower",
    studio_booking: "studio_booking",
    class_missing_reports: "class_missing_reports",
    report_absence_of_teacher: "report_absence_of_teacher",
    report_absence_of_student_by_course: "report_absence_of_student_by_course",
    report_absence_of_student_by_list: "report_absence_of_student_by_list",

    users_list: "users_list",
    edit_user: "edit_user",
    create_user: "create_user",

    courses_list: "courses_list",
    courses_SMU_list: "courses_SMU_list",
    edit_course: "edit_course",
    create_course: "create_course",
    create_SMU_course: "create_SMU_course",
    create_summer_course: "create_summer_course",

    families_list: "families_list",
    edit_family: "edit_family",
    create_family: "create_family",

    programs_list: "Programs list",
    edit_program: "edit_program",
    create_program: "create_program",

    send_mail: "send_mail",
    mail_template: "mail_template",
    create_mail_template: "create_mail_template",
    edit_mail_template: "edit_mail_template",

    schema: "schema",

    imported_student_list: "imported_student_list",

    teacher_skills: "teacher_skills",
    course_types: "course_types",
    extra_salary_category: "extra_salary_category",

    studios: "studios",
    holidays: "holidays",
    admin_and_staff: "admin_and_staff",

    teacher_salaries: "teacher_salaries",

    payment: "payment_title",

    page_404: "page_404",
    set_up_other: "set_up_other",
    old_imported_students: "old_imported_students",
    waiting_course: "waiting_course_list",
    create_waiting_course: "create_waiting_course",
    pdf_template: "pdf_template",
    subject: "subject",
    teaching_hour_report: "teaching_hour_report_lower"
}

export const ALERT = {
    quit_but_not_saved: "You have some changes. Click OK to discard or click Cancel to continue edit, then click Update below to save your changes.",
}

export const semesterOptions = [{ label: "VT", value: "VT" }, { label: "HT", value: "HT" }, {label: "ST", value: "ST"}]
export const roles = [{ value: "all", label: "All" }, { value: "student", label: "Student" }, { value: "teacher", label: "Teacher" }]
export const removeUserRoles = [{ value: "student", label: "Student" }, { value: "teacher", label: "Teacher" }, { value: "assistance", label: "Assistance" }]
export const adminStaffRols = [{ value: "admin", label: "Admin" }, { value: "staff", label: "Staff" }, { value: "developer", label: "Developer" }]
export const activeOptions = t => ([{ label: t('non_active'), value: 0 }, { label: t('active'), value: 1 }])
export const paymentOptions = t => ([{ label: t('all'), value: 0 }, { label: t('yes'), value: 1 }, { label: t('no'), value: 2 }])
export const semester = moment().format("MM/DD") >= moment("8/1", "MM/DD").format("MM/DD") ? semesterOptions[1] : semesterOptions[0]

export const langOptions = [{ label: "English", value: "en" }, { label: "Swedish", value: "se" }]
export const optionImport = {
    lines: [{ id: 1, name: "ballet_line", swe: "Ballet line" }, { id: 2, name: "dancing_line", swe: "Danslinje" }, { id: 3, name: "musical_line", swe: "Musical line" }, { id: 4, name: "street_line", swe: "Street line" }],
    courses: [{ id: 1, name: "base_kids", swe: "BaseKids (3-10 år)" }, { id: 2, name: "ballet", swe: "Balett" }, { id: 3, name: "jazz", swe: "Jazz" }, { id: 4, name: "modern", swe: "Modern" }, { id: 5, name: "tap", swe: "Tapp" }, { id: 6, name: "street", swe: "Street" }],
    others: [{ id: 1, name: "open_classes", swe: "Öppna klasser (drop-in)" }, { id: 2, name: "dance_camp", swe: "Dance camp / Early summner course" }],
    trainings: [{ id: 1, name: "day_base", swe: "DayBase" }, { id: 2, name: "alhanko", swe: "Alhanko Academy of Ballet" }],
    levels: [{ id: 1, name: "beginner", swe: "Nybörjare" }, { id: 2, name: "conditinuation", swe: "Conditinuation (dansat i några år)" }, { id: 3, name: "between_dance", swe: "Mellan (dansade flera gånger i veckan i flera år)" }, { id: 4, name: "advanced_dance", swe: "Advanced (dansar professionellt, går till en yrkesutbildning eller annan avancerad linje)" }]
}

export const termOptions = [
    { label: "10", value: 10 },
    { label: "30", value: 30 },
    { label: "60", value: 60 },
    { label: "90", value: 90 },
    { label: "120", value: 120 },
    { label: "150", value: 150 },
    { label: "180", value: 180 },
    { label: "210", value: 210 },
    { label: "240", value: 240 },
    { label: "270", value: 270 },
    { label: "300", value: 300 },
    { label: "330", value: 330 },
    { label: "360", value: 360 }
]

export const extraSalaryTypes = ["Default", "AAB", "SMU"];
export const AAB_COURSE_TYPE = "AAB";
export const SMU_COURSE_TYPE = "SMU";
export const AAB_COURSE_TYPE_COLOR = "#ff9f43";
export const SMU_COURSE_TYPE_COLOR = "#1dd1a1";
export const DEFAULT_COURSE_TYPE_COLOR = "#54a0ff";