export default (
    state = {
        payment: [],
        pageSize: localStorage.getItem("paymentPageSize") ? Number(localStorage.getItem("paymentPageSize")) : 100,
        paymentType: { value: 'all', label: 'All' },
        status: { value: 'new', label: 'New' },
        searchString: "",
        allReady: false,
        wrongPayments: []
    },
    action,
) => {
    switch (action.type) {
        case "GET_PAYMENT_LIST":
            return {
                ...state,
                payment: action.payload ? action.payload.payments : [],
                allReady: action.payload ? action.payload.allReady : false,
                currentPage: action.currentPage,
                searchString: action.searchString,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount,
                paymentType: action.type,
                status: action.status
            };
        case "CHANGE_PAGE_SIZE_PAYMENT":
            return {
                ...state,
                pageSize: action.payload
            }
        case "GET_WRONG_PAYMENTS":
            return {
                ...state,
                wrongPayments: action.payload
            }
        default:
            return state;
    }
};
