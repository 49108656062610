import moment from "moment";

const initState = {
  data: [],
  searchString: "",
  year: moment().year(),
  semester: { label: "VT", value: "VT" },
  pageCount: 0,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_DROP_IN_STUDENTS":
      return {
        ...state,
        data: action.payload || [],
        currentPage: action.currentPage,
        searchString: action.searchString,
        sortBy: action.sortBy,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
      };
    case "SET_DROP_IN_STUDENTS_SEARCH_STRING":
      return {
        ...state,
        searchString: action.payload,
      };

    case "SET_DROP_IN_COURSE_YEAR":
      return {
        ...state,
        semester: action.payload,
      };

    case "SET_DROP_IN_COURSE_SEMESTER":
      return {
        ...state,
        year: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
