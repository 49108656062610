
export default (
  state = {
    studioName: [],
    totalStudio: [],
    studioNameInformation: {},
    pageSize: localStorage.getItem("studioPageSize") ? Number(localStorage.getItem("studioPageSize")) : 30
  },
  action,
) => {
  switch (action.type) {
    case "GET_STUDIO_NAME_LIST":
      return {
        ...state,
        studioName: action.payload,
        currentPage: action.currentPage,
        searchString: action.searchString,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
      };
    case "GET_STUDIO_NAME_INFORMATION":
      return {
        ...state,
        studioNameInformation: action.payload,
      };
    case "GET_ALL_STUDIO":
      return {
        ...state,
        totalStudio: action.payload,
      };

    case "CHANGE_PAGE_SIZE_STUDIO":
      return {
        ...state,
        pageSize: action.payload
      }
    default:
      return state;
  }
};
