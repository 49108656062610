export default (state = {
    subjects: [],
    currentPage: 1,
    totalItems: 0,
    pageCount: 1,
    pageSize: localStorage.getItem("subjectPageSize") ? Number(localStorage.getItem("subjectPageSize")) : 30
}, action) => {
    switch (action.type) {
        case "SET_SUBJECT_LIST":
            return {
                ...state,
                subjects: action.payload,
                currentPage: action.currentPage,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount
            }

        case "CHANGE_PAGE_SIZE_SUBJECT":
            return {
                ...state,
                pageSize: action.payload
            }

        default:
            return state
    }
}