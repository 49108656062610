const modal = (
  state = {
    modal: {
      type: "primary",
      title: "",
      cb: null,
      componentContent: null,
    },
  },
  action,
) => {
  switch (action.type) {
    case "SET_INPUT_MODAL":
      return {
        ...state,
        modal: {
          title: action.modal.title,
          inputValue: action.modal.inputValue,
          componentContent: action.modal.componentContent,
          cb: action.modal.cb,
        },
      };
    default:
      return state;
  }
};

export default modal;
