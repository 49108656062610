
export default (
  state = {
    course: [],
    pageSize: localStorage.getItem("waitingCoursePageSize") ? Number(localStorage.getItem("waitingCoursePageSize")) : 30,
    searchString: '',
    currentPage: 1,
  },
  action,
) => {
  switch (action.type) {
    case "SET_WAITING_COURSE_LIST":
      return {
        ...state,
        course: action.payload,
        currentPage: action.currentPage,
        searchString: action.searchString,
        totalItems: action.totalItems,
        pageCount: action.pageCount,
      };

    case "CHANGE_PAGE_SIZE_WAITING_COURSE":
      return {
        ...state,
        pageSize: action.payload
      }

    case "SET_WAITING_COURSE_SEARCH_STRING":
      return {
        ...state,
        searchString: action.payload
      }

    default:
      return state;
  }
};
