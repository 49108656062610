import React from "react"
import { connect } from "react-redux"
import { Formik } from "formik"
import * as yup from "yup"
import { Button, Label, Input, Row, Col, CustomInput } from "reactstrap"

import { setForm, importData, resetForm } from "../redux/action"
import history from "../../../util/history"
import { optionImport } from "../../../util/Constant"
import { formatImportStudentDataForm1 } from "../../../util/helper"

const schema = yup.object({
    lines: yup.array().of(yup.string()),
    courses: yup.array().of(yup.string()),
    others: yup.array().of(yup.string()),
    trainings: yup.array().of(yup.string()),
    levels: yup.array().of(yup.string()),
    previousExp: yup.string(),
    otherInfo: yup.string()
})

const btnStyle = { padding: "8px 16px", fontSize: "1.1rem" }

const Interested = props => {
    const interesArr = [
        { name: "lines", propName: "lines" },
        { name: "courses", propName: "courses" },
        { name: "others", propName: "others" },
        { name: "training", propName: "trainings" },
        { name: "your_level", propName: "levels" }
    ]

    const { setForm, t } = props

    const onBack = () => {
        if (props.form1.under18) {
            setForm(1, { goToSubmit: false })
        } else {
            history.push("/form1")
        }
    }

    const submitImportStudent = async data => {
        const importData = formatImportStudentDataForm1(data)
        await props.importData(importData)
    }

    return <Formik
        initialValues={{
            lines: [],
            courses: [],
            others: [],
            trainings: [],
            levels: [],
            previousExp: "",
            otherInfo: ""
        }}
        validationSchema={schema}
        onSubmit={async (values, actions) => {
            values.goToSubmit = true
            await props.setForm(1, values)
            submitImportStudent({ ...props.form1, ...values })
        }}
    >
        {props => {
            const renderCheckBox = (name, propName) => {
                return <Row className="mb-3">
                    <Col md={12} className="mb-2">
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t(name)}</Label>
                    </Col>
                    <Col md={12}>
                        {optionImport[propName].map((item, index) => <div className="d-flex align-items-center mb-2" key={index}>
                            <CustomInput
                                type="checkbox"
                                id={`${propName}-${index}`}
                                onClick={e => {
                                    if (e.target.checked) {
                                        props.setFieldValue(propName, [...props.values[propName], item.id])
                                    } else {
                                        props.setFieldValue(propName, props.values[propName].filter(id => id !== item.id))
                                    }
                                }}
                                checked={props.values[propName].includes(item.id)}
                            />
                            <p className="mb-0" style={{ fontSize: '1.1rem' }}>{t(item.name)}</p>
                        </div>)}
                    </Col>
                </Row>
            }

            return (<>
                <h3 className="font-weight-bold">{t("interest")}</h3>
                <h4 className="font-weight-bold">{t("is_interested_following")}</h4>
                <hr />

                <>
                    {interesArr.map((item, index) => <div key={index}>{renderCheckBox(item.name, item.propName)}</div>)}
                </>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("previous_exp")}</Label>
                    </Col>
                    <Col md={12}>
                        <Input
                            type="textarea"
                            rows={5}
                            maxLength={500}
                            name="previousExp"
                            value={props.values.previousExp}
                            onChange={props.handleChange}
                        />
                        <p>{t("500_maximum_character", { count: props.values.previousExp.length })}</p>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={12}>
                        <Label className="font-weight-bold" style={{ fontSize: "1.1rem" }}>{t("other_information")}</Label>
                    </Col>
                    <Col md={12}>
                        <Input
                            type="textarea"
                            rows={5}
                            maxLength={500}
                            name="otherInfo"
                            value={props.values.otherInfo}
                            onChange={props.handleChange}
                        />
                        <p>{t("500_maximum_character", { count: props.values.otherInfo.length })}</p>
                    </Col>
                </Row>
                <hr />

                <Button outline color="danger" className="mr-3" style={btnStyle} onClick={onBack}>{t("previous")}</Button>
                <Button outline color="danger" style={btnStyle} onClick={props.handleSubmit}>{t("submit")}</Button>
            </>)
        }}
    </Formik>
}

const mapStateToProps = state => ({
    form1: state.importForm.form1
})

const mapDispatchToProp = {
    setForm,
    importData,
    resetForm
}

export default connect(mapStateToProps, mapDispatchToProp)(Interested)