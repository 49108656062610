
export default (
    state = {
        teacherSkill: [],
        totalTeacherSkill: [],
        createStatus: 0,
        editStatus: 0,
        pageSize: localStorage.getItem("skillPageSize") ? Number(localStorage.getItem("skillPageSize")) : 30
    },
    action,
) => {
    switch (action.type) {
        case "GET_SKILL_LIST":
            return {
                ...state,
                teacherSkill: action.payload,
                currentPage: action.currentPage,
                searchString: action.searchString,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount,
            };
        case "GET_ALL_SKILL":
            return {
                ...state,
                totalTeacherSkill: action.payload,
            };

        case "CHANGE_PAGE_SIZE_SKILL":
            return {
                ...state,
                pageSize: action.payload
            }
        default:
            return state;
    }
};
