import { CHANGE_PAGE_SIZE_ORDER, GET_ALL_ORDER, GET_ORDER_LIST } from "./actionType";

interface PayloadAction {
  type: string;
  payload: any;
}
const initialState = {
  teacherOrder: [],
  totalTeacherOrder: [],
  createStatus: 0,
  editStatus: 0,
  pageSize: localStorage.getItem("orderPageSize")
    ? Number(localStorage.getItem("orderPageSize"))
    : 30,
  currentPage: 0,
  searchString: "",
  sortBy: "",
  totalItems: 0,
  pageCount: 0,
};
export default (state = initialState, action: PayloadAction) => {
  switch (action.type) {
    case GET_ORDER_LIST:
      return {
        ...state,
        teacherOrder: action.payload.data,
        currentPage: action.payload.currentPage,
        searchString: action.payload.searchString,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
        totalItems: action.payload.totalItems,
        pageCount: action.payload.pageCount,
      };
    case GET_ALL_ORDER:
      return {
        ...state,
        totalTeacherOrder: action.payload,
      };

    case CHANGE_PAGE_SIZE_ORDER:
      return {
        ...state,
        pageSize: action.payload,
      };
    default:
      return state;
  }
};
