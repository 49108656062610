import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from "reactstrap";

import setInputModal from "./action";

class AppAlert extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      value: 0,
    };
  }

  delay = 0;

  componentWillReceiveProps(newProps) {
    if (newProps.modal.title.length > 0) {
      this.setState({ visible: true, value: newProps.modal.inputValue });
    }
  }

  onDismiss = () => {
    this.setState({ visible: false });
    this.props.setInputModal({ title: "", cb: null });
  };

  cb = () => {
    this.onDismiss();
    this.props.modal.cb(this.state.value);
  };

  render() {
    return this.props.modal.title.length > 0 ? (
      <>
        <Modal isOpen={this.state.visible} toggle={this.onDismiss}>
          <ModalHeader toggle={this.onDismiss}>{this.props.modal.title}</ModalHeader>
          <ModalBody>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              {this.props.modal.componentContent()}
            </div>
            <Input
              value={this.state.value}
              rows={5}
              type="number"
              onChange={event => this.setState({ value: event.target.value })}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.cb}>
              Update
            </Button>{" "}
            <Button color="secondary" onClick={this.onDismiss}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    ) : null;
  }
}

const mapStateToProps = state => ({
  modal: state.inputModal.modal,
});

const maptDispatchToProps = dispatch => ({
  setInputModal: inputModal => dispatch(setInputModal(inputModal)),
});

export default connect(
  mapStateToProps,
  maptDispatchToProps,
)(AppAlert);
