import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { withTranslation } from "react-i18next"

import FormHeader from "./FormHeader"
import history from "../../util/history"

const Thanks = props => {
    const availablePaths = ["/form/1", "/form/2"]
    const { t, location: { pathname } } = props

    useEffect(() => {
        if (!availablePaths.includes(pathname)) {
            history.push("/")
        }
    }, [])

    const form1 = {
        backUrl: "https://www.base23.se",
        backText: "back_to_home_page",
        title: "form1_thanks_title",
        content1: "form1_thanks_content1",
        content2: "form1_thanks_content2"
    }

    const form2 = {
        backUrl: "https://www.base23.se/smu",
        backText: "back_to_smu_start_page",
        title: "form2_thanks_title",
        content1: "form2_thanks_content1",
        content2: "form2_thanks_content2"
    }

    const BackBtn = ({ backUrl, backText }) => <div>
        <a
            href={backUrl}
            className="text-danger font-weight-bold"
            style={{ fontSize: "1.1rem" }}
        >
            {t(backText)}
        </a>
    </div>

    const form = pathname === availablePaths[0] ? form1 : form2

    const ThankForm = () => <>
        <h2 className="mb-2">{t(form.title)}</h2>
        <p style={{ fontSize: "1.1rem" }} className="mb-2">
            {t(form.content1)}
        </p>
        <p style={{ fontSize: "1.1rem" }} className="mb-2">
            {t(form.content2)}
        </p>
        <BackBtn backUrl={form.backUrl} backText={form.backText} />
    </>

    return <>
        <FormHeader />
        <Container>
            <ThankForm />
        </Container>
    </>
}

export default withTranslation("common")(Thanks)