const alert = (
  state = {
    alert: {
      type: "info",
      message: "",
    },
  },
  action,
) => {
  switch (action.type) {
    case "SET_ALERT":
      return {
        ...state,
        alert: {
          message: action.alert.message,
          type: action.alert.type || state.alert.type,
        },
      };
    default:
      return state;
  }
};

export default alert;
